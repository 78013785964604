<template>
  <div class="modal fade " id="orderQuickViewModal" tabindex="-1" role="dialog" aria-labelledby="orderQuickViewModal"
       aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable modal-xl modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header bg-primary">
          <h1 class="modal-title white">Order Details </h1>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <i class="bx bx-x"></i>
          </button>
        </div>
        <div class="modal-body" id="printDiv">
          <div>
            <h4>
              <span class="text-secondary text-light" v-if="orderDetails.order_no">({{ orderDetails.order_no }})</span>

              <span v-if="order.appointment">
                            <span><i class="bx bx-link" style="font-size: 1.5rem;"></i> </span>
                            <span v-if="order.appointment.type === 'Home'">
                                <i class="bx bx-home" style="font-size: 1.5rem;"></i>
                            </span>
                            <span v-else><i class="bx bxs-business" style="font-size: 1.5rem;"></i> </span>
                            <span class="text-secondary text-light">({{ order.appointment.reference }})</span>
                        </span>
            </h4>
          </div>
          <div>
            <!--            order & customer details-->
            <div class="row">
              <div class="col-md-6" v-if="orderDetails.user !== undefined">
                <div>
                  <span class=""><i class='bx bx-user' :style="{fontSize:'15px'}"></i></span>

                  <span class="text-capitalize">{{
                      `${orderDetails?.user?.first_name} ${orderDetails?.user?.last_name}`
                    }}</span>
                </div>
                <div>
                  <span class="" v-if="orderDetails?.user?.email"><i class='bx bx-mail-send'
                                                                     :style="{fontSize:'15px'}"></i></span>
                  <span>{{ orderDetails?.user?.email }}</span>
                </div>
                <div>
                  <span class="" v-if="orderDetails?.user?.phone_number"><i class='bx bx-phone'
                                                                            :style="{fontSize:'15px'}"></i></span>
                  <span>{{ orderDetails?.user?.phone_number }}</span>
                </div>
                <div>
                  <span class="" v-if="orderDetails?.user?.customer?.address?.id"><i class='bx bx-map'
                                                                                     :style="{fontSize:'15px'}"></i> </span>
                  <span>{{ userFullAddress(orderDetails.user.customer) }}</span>
                </div>

              </div>
              <div class="col-md-6 d-flex justify-content-end align-items-end">
                <div>
                  <!--                  <div class="d-flex">
                        <span class=""><i class='bx bxs-parking bg-warning rounded-sm text-white'
                                          :style="{fontSize:'28px',padding:'5px 5px'}"></i></span>
                        <div class="" :style="{paddingLeft:'5px'}">
                          <small class="py-0 my-0">PARKING</small>
                          <p class="mb-0">
                            <small>{{ appointmentDetails.parking ? appointmentDetails.parking : 'No' }}</small></p>
                        </div>
                      </div>-->
                  <div v-if="orderPayment !== undefined">
                    <h4 class="">${{ parseFloat(orderPayment.total / 100).toFixed(2) }} <small
                        style="font-size: 0.8rem;">AUD</small></h4>
                  </div>

                  <div class="">
                    <span class="font-weight-bolder">Status:</span> <span class="badge"
                                                                          :class="orderDetails.status=== 'Pending'?'badge-secondary':'badge-danger'">
                                        {{ orderDetails.status }}</span>
                  </div>
                  <div class="" v-if="orderPayment!== undefined">
                    <span class="font-weight-bolder">Payment Status:</span> <span class="badge "
                                                                                  :class="orderPayment.remaining === 0 ? 'badge-success' : (orderPayment.paid === 0 ? 'badge-danger' :'badge-primary' )"> {{
                      orderPayment.remaining === 0 ? 'Full Paid' : (orderPayment.paid === 0 ? 'Unpaid' : 'Partially Paid')
                    }}</span>
                  </div>
                  <div class="font-weight-bolder" v-if="orderPayment.remaining">
                    Remaining amount: <span
                      class="font-weight-normal text-warning">${{ (orderPayment.remaining / 100).toFixed(2) }}</span>
                  </div>
                </div>
              </div>
            </div>
            <!--            order date & time-->
            <div class="row mt-1">
              <div class="col-12  py-1 text-dark" :style="{backgroundColor:'#edf2f9'}">
                <h5 class="text-center mb-0 font-weight-bold" v-if=" orderDetails.delivery_date">Delivery: {{
                    new Date(`${orderDetails.delivery_date}`).toLocaleDateString("en-AU", {
                      day: "numeric",
                      month: "short",
                      year: "numeric",
                    })
                  }}</h5>
                <p class="text-center mb-0"> {{
                    new Date(`${orderDetails.order_date}`).toLocaleDateString("en-AU", {
                      day: "numeric",
                      month: "short",
                      year: "numeric",
                    })
                  }}</p>
              </div>
            </div>
            <!--            order product item-->
            <div class="row pt-1">
              <div class="col-12">
                <div class="table-responsive">
                  <table class="table">
                    <thead class="thead-light">
                    <tr>
                      <th class="position-relative" style="width: 60%">PRODUCT</th>
                      <th class="position-relative" style="width: 10%">QTY</th>
                      <th class="position-relative" style="width: 10%">UNIT PRICE</th>
                      <th class="position-relative" style="width: 20%">PRICE</th>
                    </tr>
                    </thead>
                    <tbody v-if="orderDetails.details!==undefined && orderDetails.status === 'Pending'">
                    <tr v-for="(cartDetails) in orderDetails.details.cart_data" :key="cartDetails.product_id">
                      <td class="text-capitalize text-bold-500">
                        <div class="d-flex">
                          <span>{{ cartDetails.name }}({{ cartDetails?.product_price?.condition_name }})</span>
                          <span class="ml-auto" style="font-size: 0.8rem">{{
                              cartDetails.placed_date != null ? new Date(`${cartDetails.placed_date}`).toLocaleDateString("en-AU", {
                                day: "numeric",
                                month: "short",
                                year: "numeric",
                              }) : ''
                            }}</span>
                        </div>
                        <div v-if="cartDetails.product_items !== undefined && cartDetails.product_items?.length !== 0">
                          <ul>
                            <div v-for="(singleItem) in cartDetails.product_items" :key="singleItem.id">
                              <li v-if="singleItem.item_obj.condition === cartDetails.product_price.condition_name ">
                                {{ singleItem.item_obj.serial_number }}
                              </li>
                            </div>
                          </ul>
                        </div>
                      </td>
                      <td>{{ cartDetails.product_price.quantity }}</td>
                      <td class="text-bold-500">
                        ${{ parseFloat((cartDetails?.product_price?.amount) / 100).toFixed(2) }}
                      </td>
                      <td class="text-bold-500">
                        ${{ parseFloat((cartDetails?.product_price?.sub_total) / 100).toFixed(2) }}
                      </td>
                    </tr>
                    </tbody>
                    <tbody v-if="orderDetails.status === 'Processing'">
                    <tr v-for="(key) in orderDetails?.customProducts ?? []" :key="key">

                      <td class="text-capitalize text-bold-500">
                        <div class="d-flex">
                          <span>{{ key.name }}</span>
                          <span class="ml-auto" style="font-size: 0.8rem">{{
                              key.placed_date != null ? new Date(`${key.placed_date}`).toLocaleDateString("en-AU", {
                                day: "numeric",
                                month: "short",
                                year: "numeric",
                              }) : ''
                            }}</span>
                        </div>
                        <div v-if="key.productItem !== undefined && key.productItem?.length !== 0">
                          <ul>
                            <div v-for="(singleItem) in key.productItem" :key="singleItem.id">
                              <li>
                                {{ singleItem.serialNumber }} <span
                                  v-if=" singleItem.serialNumber">({{ singleItem.condition }})</span>
                              </li>

                            </div>
                          </ul>
                        </div>
                      </td>
                      <td>{{ key.productItem.length }}</td>
                      <td class="text-bold-500">
                        ${{ parseFloat((key.productItem[0].unitPrice) / 100).toFixed(2) }}
                      </td>
                      <td class="text-bold-500">
                        ${{ ((key.productItem[0].unitPrice * key.productItem.length) / 100).toFixed(2) }}
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <!--                  charges list start -->
          <div class="row">
            <div class="col-md-12 d-flex justify-content-end align-items-end">
              <div>
                <div>
                  <span>Item Price :</span> ${{ parseFloat(subTotal / 100).toFixed(2) }} <small
                    style="font-size: 0.8rem;">AUD</small>
                </div>
                <div v-for="(charge,index) in orderPayment.chargesDetails" :key="index">
                  <div v-if="index !== 0">
                    <i v-if="charge.name === 'discount'" style="font-size:14px;cursor:pointer;"
                       @click="openDiscountDetailsModal" class="bx bx-show"></i>
                    <span class="text-capitalize">{{ charge.name }} ({{ charge.sign }}) : </span>
                    ${{ parseFloat(charge.amount / 100).toFixed(2) }} <small style="font-size: 0.8rem;">AUD</small>
                  </div>
                </div>
                <div>
                  <span>Sub Total :</span> ${{ parseFloat(orderPayment.total / 100).toFixed(2) }} <small
                    style="font-size: 0.8rem;">AUD</small>
                </div>
                <div>
                  <span>Paid :</span> ${{ parseFloat(orderPayment.paid / 100).toFixed(2) }} <small
                    style="font-size: 0.8rem;">AUD</small>
                </div>
                <div>
                  <span>Card Charges :</span> ${{ parseFloat(orderPayment.cardCharges / 100).toFixed(2) }} <small
                    style="font-size: 0.8rem;">AUD</small>
                </div>
                <div>
                  <span>Grand Total :</span> ${{
                    (parseFloat(parseFloat(orderPayment.total) + parseFloat(orderPayment.cardCharges)) / 100).toFixed(2)
                  }} <small style="font-size: 0.8rem;">AUD</small>
                </div>
              </div>
            </div>
          </div>

          <div v-if="orderDetails.orderNotes?.length" class="p-2" :style="{backgroundColor:'#fff4a3'}">
            <p v-for="(note,index) in orderDetails.orderNotes" :style="{color:'#000'}" :key="note.id" class="mb-0">
              <span v-if="index === 0" class="font-weight-bolder">Notes: </span>{{ note.description }}
            </p>
          </div>
          <!-- appointment time logs remark & signature -->
          <div class="py-3" v-if="orderDetails?.time_logs?.length > 0">
            <h4 class="text-secondary">Agreement</h4>
            <div v-for="(timeLog,index) in orderDetails.time_logs" :key="index">
              <span class="d-flex my-0">
                <p class="mb-0"><strong>Status: </strong> Agree</p>
                <p class="mb-0 mx-3"><strong>{{
                    new Date(`${timeLog.created_at}`).toLocaleDateString("en-AU", {
                      day: "numeric",
                      month: "short",
                      year: "numeric",
                    })
                  }}</strong></p>
              </span>
              <p class="mb-0"><strong>Issue Notes: </strong> {{ timeLog.remark }}</p>
              <p class="mb-0"><strong>Signature: </strong>
                <img :src="timeLog.signature" alt="customer-signature" style="width:100px; height:100px">
              </p>
              <hr>
            </div>
          </div>
        </div>
        <div class="modal-footer border-0 py-1">
          <button type="button" class="btn btn-primary" data-dismiss="modal">
            <i class="bx bx-x d-block d-sm-none"></i>
            <span class="d-none d-sm-block">Close</span>
          </button>
        </div>
        <div class="" data-toggle="modal" data-target="#chargesDetails"></div>
        <order-charges-details-modal modal-name="chargesDetails" modal-title="Discount"
                                     :appointmentCharges="currentDiscountCharges"></order-charges-details-modal>
      </div>
    </div>
  </div>
</template>

<script>
import OrderChargesDetailsModal
  from "@/views/backEnd/appointments/appointmentList/includes/AppointmentChargesDetailsModal";
import PrintElement from "@/components/backEnd/mixins/PrintElement";

export default {
  name: "OrderQuickViewModal",
  props: ['order', 'orderPayment'],
  components: {
    OrderChargesDetailsModal
  },
  mixins: [PrintElement],
  data() {
    return {
      currentDiscountCharges: [],
      orderDetails: {},
      subTotal: 0,
    }
  },
  watch: {
    order(order) {
      this.orderDetails = order;
      let productItemsSet = {};
      if (this.orderDetails.status === 'Processing') {

        // product item list data making
        this.orderDetails.orderProductItems.forEach((item) => {
          if (productItemsSet[item.productItem.product.id]) {
            productItemsSet[item.productItem.product.id].productItem.push({
              condition: item.productItem.condition,
              serialNumber: item.productItem.serial_number,
              unitPrice: item.productItem.productItemTransaction?.sell_price
            });
            return;
          }

          productItemsSet[item.productItem.product.id] = {
            id: item.productItem.product.id,
            name: item.productItem.product.name,
            placed_date: item.placed_date,
            productItem: [{
              condition: item.productItem.condition,
              serialNumber: item.productItem.serial_number,
              unitPrice: item.productItem.productItemTransaction?.sell_price
            }]

          };

        })
        const productItems = [...Object.values(productItemsSet)];
        this.orderDetails.customProducts = productItems.sort((a, b) => (new Date(a.placed_date) - new Date(b.placed_date)));

        let subTotal = 0;
        // sub total calculation
        for (const key in this.orderDetails.customProducts) {
          subTotal = subTotal + this.orderDetails.customProducts[key].productItem.reduce((acc, cur) => {
            return acc + cur.unitPrice;
          }, 0);

        }
        this.subTotal = subTotal;

      }

      if (this.orderDetails.status === 'Pending') {
        this.subTotal = order?.details?.cart_data.reduce(function (acc, cur) {
          return acc + cur.product_price.sub_total
        }, 0);
      }

    }
  },

  computed: {
    filterOrderNotes() {
      return this.orderDetails &&
      this.orderDetails.orderNotes ?
          this.orderDetails.orderNotes.find(({
                                               type,
                                               user_type
                                             }) =>
              (type === 'Additional' && user_type === 'Customer')) : [];
    },
  },
  methods: {
    userFullAddress(customerObj) {
      let businessName = customerObj.address?.business?.name ?? '';
      let addressStreet = customerObj.address.street;
      let addressSuburb = customerObj.address.suburb;
      let addressState = customerObj.address.state.toUpperCase();
      let addressPostCode = customerObj.address.post_code;
      let addressCountry = customerObj.address.country;
      return customerObj.type === 'Business' ?
          `${businessName} (${addressStreet}, ${addressSuburb} ${addressState} ${addressPostCode}, ${addressCountry})` :
          `${addressStreet}, ${addressSuburb} ${addressState} ${addressPostCode}, ${addressCountry}`;
    },

    openDiscountDetailsModal() {
      this.currentDiscountCharges = this.orderPayment.chargesDetails.find(({name}) => name === 'discount')?.details ?? [];
      document.querySelector(`[data-target='#chargesDetails']`).click()
    }
  }

}
</script>

<style>

</style>

<template>
  <div class="modal fade " id="editOrderStatusModal" tabindex="-1" role="dialog" aria-labelledby="editOrderStatusModal"
       aria-hidden="true">
    <div class="modal-dialog  modal-md modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header bg-primary">
          <h1 class="modal-title white">Change Order Status</h1>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <i class="bx bx-x"></i>
          </button>
        </div>
        <div class="modal-body">
          <div>
            <div class="row">
              <div class="col-12">
                <h4 class="text-dark ">Status*</h4>
                <div class="form-group">
                  <div class="controls">
                    <VueMultiselect v-model="selectedStatus" class="multiselect-blue" :options="statusOption"
                                    :close-on-select="true" label="name" track-by="name" :show-labels="false"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer border-0 pt-0">
          <button type="button" class="btn btn-primary" data-dismiss="modal">
            <i class="bx bx-x d-block d-sm-none"></i>
            <span class="d-none d-sm-block">Save </span>
          </button>

        </div>
        <div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "EditPaymentStatusModal",
  data() {
    return {
      selectedStatus: {
        name: 'None',
        value: ''
      },
      statusOption: [
        {
          name: 'Paid',
          language: 'Paid'
        },
        {
          name: 'Unpaid',
          language: 'Unpaid '
        },

      ],
    }
  }

}
</script>

<style>

</style>

<template>
  <AppLayout>
    <template v-slot:appContent>
      <section class="users-list-wrapper">

        <div class="d-sm-flex justify-content-between align-items-center pt-2 pt-md-2 pt-xl-0 ">
          <h4 class="">Orders/List</h4>

          <div>
            <router-link :to="can('order-create') ? {name:'appOrderCreate'} : '#'">
              <span :title="can('order-create') ? 'Create' : 'Create Forbidden'"
                    class="glow d-flex align-items-center">
                <i class='bx bx-plus bx-flip-vertical'></i>&nbsp;Create</span>
            </router-link>
          </div>
        </div>
        <div class="users-list-filter px-1 my-2 py-2 border rounded">
          <div class="row">

            <div class="col-12 col-md-6 col-lg-3 ">
              <div class="form-group">
                <div class="">
                  <label>Order No</label>
                  <input v-model="getOrdersParams.order_no" type="text" class="form-control" placeholder="Order no..."
                         name="order_no">
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-3">
              <div class="form-group">
                <label>Technician</label>
                <VueMultiselect v-model="selectedTechnician" class="" placeholder="select status"
                                :options="technicianOptions" :close-on-select="true"
                                label="name" track-by="value" :show-labels="false" :allow-empty="false"/>
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-3 ">
              <div class="form-group">
                <div class="">
                  <label>CUSTOMER/ADDRESS SEARCH</label>
                  <input v-model="getOrdersParams.where_has_user_search_query" type="text" class="form-control"
                         placeholder="Search by Customer or Address">
                </div>
              </div>
            </div>

            <div class="col-12 col-md-6 col-lg-3 ">
              <div class="form-group">
                <label>Order date form</label>
                <div class="d-flex align-items-center date-picker-wrapper">
                  <div class="w-100 ">
                    <DatePicker v-model="order_date_form" :update-on-input="true" :masks="{input: ['DD MMMM YYYY']}"
                                :max-date="new Date()"
                                :model-config="{ type: 'string', mask: 'YYYY-MM-DD'}">
                      <template v-slot="{ inputValue ,togglePopover  }">
                        <div class="d-flex align-items-center" @click="togglePopover()" style="cursor: pointer;">
                          <i class='bx bx-calendar mr-1'></i>
                          <input class="form-control" :value="inputValue" style="cursor: pointer;"/>
                        </div>
                      </template>
                    </DatePicker>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-3">
              <div class="form-group">
                <label>Order date to</label>
                <div class="d-flex align-items-center date-picker-wrapper">
                  <div class="w-100 ">
                    <DatePicker v-model="order_date_to" :update-on-input="true" :masks="{input: ['DD MMMM YYYY']}"
                                :max-date="new Date()"
                                :model-config="{ type: 'string', mask: 'YYYY-MM-DD'}">
                      <template v-slot="{ inputValue ,togglePopover  }">
                        <div class="d-flex align-items-center" @click="togglePopover()" style="cursor: pointer;">
                          <i class='bx bx-calendar mr-1'></i>
                          <input class="form-control" :value="inputValue" style="cursor: pointer;"/>
                        </div>
                      </template>
                    </DatePicker>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-3 ">
              <div class="form-group">
                <label>Delivery date form</label>
                <div class="d-flex align-items-center date-picker-wrapper">
                  <div class="w-100 ">
                    <DatePicker v-model="delivery_date_form" :update-on-input="true" :masks="{input: ['DD MMMM YYYY']}"
                                :max-date="new Date()"
                                :model-config="{ type: 'string', mask: 'YYYY-MM-DD'}">
                      <template v-slot="{ inputValue ,togglePopover  }">
                        <div class="d-flex align-items-center" @click="togglePopover()" style="cursor: pointer;">
                          <i class='bx bx-calendar mr-1'></i>
                          <input class="form-control" :value="inputValue" style="cursor: pointer;"/>
                        </div>
                      </template>
                    </DatePicker>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-3">
              <div class="form-group">
                <label>Delivery date to</label>
                <div class="d-flex align-items-center date-picker-wrapper">
                  <div class="w-100 ">
                    <DatePicker v-model="delivery_date_to" :update-on-input="true" :masks="{input: ['DD MMMM YYYY']}"
                                :max-date="new Date()"
                                :model-config="{ type: 'string', mask: 'YYYY-MM-DD'}">
                      <template v-slot="{ inputValue ,togglePopover  }">
                        <div class="d-flex align-items-center" @click="togglePopover()" style="cursor: pointer;">
                          <i class='bx bx-calendar mr-1'></i>
                          <input class="form-control" :value="inputValue" style="cursor: pointer;"/>
                        </div>
                      </template>
                    </DatePicker>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-3">
              <div class="form-group">
                <label>Status</label>
                <VueMultiselect v-model="selectedOrderStatusForFilter" class="" placeholder="select status"
                                :options="orderStatusList" :close-on-select="true"
                                label="name" track-by="value" :show-labels="false" :allow-empty="false"/>
              </div>
            </div>


            <!-- <div class="col-12 col-lg-3">
              <div class="form-group">
                <div class="controls">
                  <label>Order Date</label>
                  <div class="d-flex align-items-center date-picker-wrapper">
                    <div class="d-flex align-items-center">
                      <i class='bx bx-calendar'></i>
                    </div>
                    <div class="w-100 pl-1">
                      <div class="w-100 ">
                        <DatePicker v-model="order_date" :update-on-input="true"
                                    mode="date"
                                    :max-date="new Date()"
                                    color="yellow"
                                    title-position="left"
                                    is-range
                                    :model-config="{ type: 'string', mask: 'YYYY-MM-DD '}">
                          <template v-slot="{ inputValue, inputEvents }">
                            <input class="form-control" :value="inputValue.start" v-on="inputEvents.start"/>
                          </template>
                        </DatePicker>
                      </div>
                    </div>
                    <div><i class='bx bx-transfer-alt'></i></div>
                    <div class="w-100 pl-1">
                      <div class="w-100 ">
                        <DatePicker v-model="order_date" :update-on-input="true"
                                    mode="date"
                                    is-range
                                    :model-config="{ type: 'string', mask: 'YYYY-MM-DD '}">
                          <template v-slot="{ inputValue, inputEvents }">
                            <input class="form-control" :value="inputValue.end" v-on="inputEvents.end"/>
                          </template>
                        </DatePicker>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> -->

            <!-- <div class="col-12 col-lg-4">
              <div class="form-group">
                <div class="controls">
                  <label>Delivery Date</label>
                  <div class="d-flex align-items-center date-picker-wrapper">
                    <div class="d-flex align-items-center">
                      <i class='bx bx-calendar'></i>
                    </div>
                    <div class="w-100 pl-1">
                      <div class="w-100 ">
                        <DatePicker v-model="delivery_date" :update-on-input="true"
                                    mode="date"
                                    is-range
                                    :model-config="{ type: 'string', mask: 'YYYY-MM-DD '}">
                          <template v-slot="{ inputValue, inputEvents }">
                            <input class="form-control" :value="inputValue.start" v-on="inputEvents.start"/>
                          </template>
                        </DatePicker>
                      </div>
                    </div>
                    <div><i class='bx bx-transfer-alt'></i></div>
                    <div class="w-100 pl-1">
                      <div class="w-100 ">
                        <DatePicker v-model="delivery_date" :update-on-input="true"
                                    mode="date"
                                    is-range
                                    :model-config="{ type: 'string', mask: 'YYYY-MM-DD '}">
                          <template v-slot="{ inputValue, inputEvents }">
                            <input class="form-control" :value="inputValue.end" v-on="inputEvents.end"/>
                          </template>
                        </DatePicker>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> -->


          </div>
          <div class="row justify-content-end">
            <div class="col-12 col-lg-2">
              <div class="form-group">
                <div class="controls">
                  <label></label>
                  <button type="reset" class="btn btn-primary btn-block glow users-list-clear mb-0"
                          @click="applyOrderFilter(null)">Search
                  </button>

                </div>
              </div>
            </div>
          </div>
          <!--          <div class="row">
                      <div class="col-12 col-lg-3">
                        <div class="form-group">
                          <div class="controls">
                            <label>Technician </label>
                            <VueMultiselect v-model="technicalValue" class="" :options="technical" :close-on-select="true"
                                            placeholder="Select a technician" label="name" track-by="name"
                                            :show-labels="false"/>
                          </div>
                        </div>
                      </div>

                      <div class="col-12 col-lg-3">
                        <div class="form-group">
                          <div class="controls">
                            <label>State </label>
                            <VueMultiselect v-model="stateValue" class="" :options="state" :close-on-select="true"
                                            placeholder="" label="name" track-by="name" :show-labels="false"/>
                          </div>
                        </div>
                      </div>


                    </div>-->
        </div>
        <div class="users-list-table">
          <div class="card">
            <div class="card-body">
              <div class="table-responsive">
                <table id="users-list-datatable" class="table">
                  <thead>
                  <tr>
                    <th class="position-relative" :style="{width:'5%'}">SL</th>
                    <th class="position-relative" :style="{width:'15%'}">ORDER ID</th>
                    <th class="position-relative" :style="{width:'20%'}">CUSTOMER</th>
                    <th class="position-relative" :style="{width:'10%'}">TECHNICIAN</th>
                    <th class="position-relative" :style="{width:'10%'}">ORDER DATE</th>
                    <th class="position-relative" :style="{width:'10%'}">DELIVERY DATE</th>
                    <th class="position-relative" :style="{width:'7%'}">STATUS</th>
                    <th class="position-relative" :style="{width:'18%'}">PRICE</th>
                    <th class="position-relative" :style="{width:'5%'}">Actions</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(order,index) in orders" :key="index">
                    <td>{{ index + 1 }}</td>
                    <td>
                      <small @click="openAppointmentDetailsModal(order.appointment.id)"
                             :title="(order?.appointment?.id) ? 'click to view appointment' : ''"
                             v-if="order?.appointment?.id"
                             :class="(order?.appointment?.id) ? 'text-danger mr-1 cursor-pointer' :  ''">
                        # {{ order.order_no }}
                      </small>
                      <small v-else>
                        # {{ order.order_no }}
                      </small>
                      <small> <i title="click to view appointment"
                                 @click="openAppointmentDetailsModal(order.appointment.id)"
                                 v-if="order?.appointment?.id"
                                 class="bx bx-link text-danger cursor-pointer bx-tada"></i>
                      </small>
                    </td>
                    <td>
                      <router-link title="Click to view customer details"
                                   :to="{name:'appCustomerView',params: { id: order.user.customer.id }}"
                                   class="text-capitalize">{{ getFullName(order.user) }}
                      </router-link>
                      <br>
                      <span class="text-light">{{ userFullAddress(order.user.customer) }}</span>
                    </td>

                    <td><span class="badge"
                              :class="order.appointment === null ? 'badge-light-glow':'badge-light-warning'"> {{
                        order.appointment !== null ? getFullName(order.appointment?.technicianAppointment?.technician?.user) : 'Individual'
                      }}</span></td>
                    <td>
                      <span class="badge-light-light">{{ getDisplayDate(order.order_date) }}</span>
                    </td>
                    <td>
                      <span v-if="order?.delivery_date" class="">{{ getDisplayDate(order.delivery_date) }}</span>
                      <span v-else class="text-danger">Not provided</span>
                    </td>
                    <td>
                      <span
                          :class="order.status==='Pending'? 'badge badge-light-success badge-pill':'badge badge-light-danger badge-pill'">{{
                          order.status
                        }}</span>
                    </td>

                    <td v-if="payments[order.id]!== undefined">
                      <div>
                        <div class="text">
                          Total: ${{ parseFloat(payments[order.id].total / 100).toFixed(2) }}
                        </div>
                        <div class="text-success"
                             v-bind:style="{cursor: 'pointer'}"
                             v-tooltip="{
                            text:`Card Charges $`+ parseFloat(payments[order.id].cardCharges / 100).toFixed(2),
                            displayArrow:true,
                            theme:{
                              color:'#000000',
                              placement:'bottom',
                              border: '1px solid red',
                              'background-color':'#FFF1DF'
                            }}">
                          Paid: ${{ parseFloat(payments[order.id].paid / 100).toFixed(2) }}
                        </div>
                        <div v-if="payments[order.id].remaining !== 0" class="text-danger">
                          Remaining: ${{ parseFloat(payments[order.id].remaining / 100).toFixed(2) }}
                        </div>
                        <div v-else class="text-success">(Fully Paid)</div>
                      </div>
                      <br>
                      <div v-if="refunds[order.id] !== undefined && refunds[order.id].status === true">
                          <span class="text-warning">
                            Refund: ${{ parseFloat(refunds[order.id].amount / 100).toFixed(2) }}
                          </span>
                      </div>
                    </td>

                    <td>
                      <div class="d-flex align-items-top justify-content-between">

                        <button @click="getSingleOrderFromOrderObj(order.id)" data-toggle="modal"
                                data-target="#orderQuickViewModal"
                                class="bg-transparent text-decoration-none cursor-pointer border-0 view-edit-delte-icon"
                                :style="{height:'18px'}"
                                :disabled="!can('order-view')"
                                :title="can('order-view') ? 'View' : 'View Forbidden'">
                          <i class='bx bx-show'></i>
                        </button>

                        <!-- <router-link v-if="!currentFranchiseeId && order.status === 'Pending'"
                                     class="text-decoration-none view-edit-delte-icon"
                                     :to="can('order-update') ? {name:'appOrderUpdateBeforeProcess',params:{id:order.id}} : '#'"
                                     :title="can('order-update') ? 'Update Order' : 'Update forbidden'"
                                     :style="{height:'18px'}"><i class='bx bx-edit'></i>
                        </router-link> -->

                        <router-link v-if="!currentFranchiseeId && order.status === 'Pending'"
                                     class="text-decoration-none view-edit-delte-icon"
                                     :to="can('order-update') ? {name:'appOrderView',params:{id:order.id}} : '#'"
                                     :title="can('order-update') ? 'Process Order' : 'Process forbidden'"
                                     :style="{height:'18px'}"><i class='bx bx-sync'></i>
                        </router-link>

                        <router-link
                            v-if="(currentFranchiseeId ? (order.details?.is_order_procure_requested && order.status === 'Pending') : false) "
                            :class="(currentFranchiseeId ? !(order.details?.is_order_procure_requested && order.status === 'Pending') : false) ? 'disable-event' : ''"
                            :to="can('order-update') ? {name:'appOrderProcure',params:{id:order.id}} : '#'"
                            :title="can('order-update') ? 'Procure' : 'Procure forbidden'"
                            :style="{height:'18px'}"><i class='bx bx-edit'></i>
                        </router-link>


                        <div class="dropdown py-0 px-0 view-edit-delte-icon" :style="{height:'18px'}">
                                                    <span type="button" class="px-0 py-0" data-toggle="dropdown"
                                                          aria-haspopup="true" aria-expanded="false">
                                                        <i class='bx bx-dots-vertical-rounded h-100'></i>
                                                    </span>
                          <div class="dropdown-menu" style="min-height: 100px">

                            <button v-if="(payments[order.id]!== undefined) && (payments[order.id].remaining > 0)"
                                    @click="getSingleOrder(order.id,'remainingPaymentModal')" class="dropdown-item"
                                    :title="canAny(['card-payment-create','cash-payment-create','eft-payment-create']) ? 'Payment Create' : 'Payment Forbidden'"
                                    :disabled="!canAny(['card-payment-create','cash-payment-create','eft-payment-create'])">
                              Add Payment
                            </button>

                            <button v-if="order.appointment === null" @click="tagAnAppointment(order)"
                                    class="dropdown-item"
                                    data-toggle="modal" data-target="#tagAnAppointmentModal"
                                    :disabled="!can('order-tag-appointment')"
                                    :title="can('order-tag-appointment') ? 'Tag An Appointment' : 'Tag An Appointment Forbidden'">
                              Tag an appointment
                            </button>

                            <button @click="getSingleOrder(order.id, 'editOrderStatusModal')" class="dropdown-item"
                                    :disabled="!can('order-status-update')"
                                    :title="can('order-status-update') ? 'Update' : 'Update Forbidden'">Change Status
                            </button>

                            <button @click="modelIdForDelete = order.id" class="dropdown-item"
                                    data-toggle="modal" data-target="#deleteOrderModal"
                                    :disabled="!can('order-delete')"
                                    :title="can('order-delete') ? 'Delete' : 'Delete Forbidden'">Delete Order
                            </button>

                            <router-link
                                :title="can('payment-view-any') ? 'View' : 'View Forbidden'"
                                :to="can('payment-view-any') ? {name:'appAccountFinancePaymentsList',params:{reference:order.order_no}} : '#'"
                                class="dropdown-item cursor-pointer" :autocorrect="{height:'18px'}">View Payments
                            </router-link>

                            <div v-if="(payments[order.id]!== undefined) && (order.status !== 'Pending')">
                              <button @click="sendPaymentRequestEmailToCustomerModalOpenHandler(order.id)"
                                      v-if="((parseFloat(payments[order.id].total / 100).toFixed(2)) > ( parseFloat(payments[order.id].paid / 100).toFixed(2)) ) "
                                      class="dropdown-item">
                                Send Payment Request Email
                              </button>
                            </div>
                            <!--                            <button :disabled="(currentFranchiseeId ? !(order.details?.isOrderAdminProcessed) : false)"
                                                                class="dropdown-item p-0">
                                                          <router-link v-show="order.status==='Pending'" class="dropdown-item"
                                                                       :to="can('order-update') ? {name:'appOrderProcure',params:{id:order.id}} : '#'"
                                                                       :title="can('order-update') ? 'Update' : 'Update forbidden'">Order procure
                                                          </router-link>
                                                        </button>-->

                            <button @click="getOrderNoteList(order.id)" class="dropdown-item"
                                    :disabled="!can('order-note-view-any')"
                                    :title="can('order-note-view-any') ? 'View' : 'View Forbidden'">View Notes
                            </button>

                            <button @click="addNote( order.id, order.user.id )" class="dropdown-item"
                                    data-toggle="modal" data-target="#orderNoteCreateModal"
                                    :disabled="!can('order-note-create')"
                                    :title="can('order-note-create') ? 'Create' : 'Create Forbidden'"
                            >Add note
                            </button>

                            <button v-if="order.status === 'Delivered' || order.status === 'Closed'"
                                    @click="getSingleOrderInvoice(order.uuid)" class="dropdown-item"
                                    :disabled="!can('order-invoice-view')"
                                    :title="can('order-invoice-view') ? 'View' : 'View Forbidden'">
                              View Invoice
                            </button>

                            <button v-if="order.status !== 'Pending'" @click="getSingleOrderUpdate(order.id)"
                                    class="dropdown-item"
                                    :disabled="!can('order-invoice-view')"
                                    :title="can('order-invoice-view') ? 'View' : 'View Forbidden'">
                              Update
                            </button>

                            <router-link
                                :to="can('order-update') ? {name:'appOrderUpdateBeforeProcess',params:{id:order.id}} : '#'"
                                v-if="order.status === 'Pending'"
                                class="dropdown-item"
                                :disabled="!can('order-update')"
                                :title="can('order-update') ? 'View' : 'View Forbidden'">
                              Update before process
                            </router-link>

                            <button
                                v-if="order?.orderJob === null && (order.status === 'Pending' || order.status === 'Processing')"
                                @click="copyToOrderJobHandler(order.id)" class="dropdown-item">
                              Copy To Jobs
                            </button>

                            <!-- <router-link v-if="!currentFranchiseeId && order.status === 'Pending'"
                                class="text-decoration-none view-edit-delte-icon"
                                :to="can('order-update') ? {name:'appOrderUpdateBeforeProcess',params:{id:order.id}} : '#'"
                                :title="can('order-update') ? 'Update Order' : 'Update forbidden'"
                                :style="{height:'18px'}"><i class='bx bx-edit'></i>
                   </router-link> -->
                            <!--    <button data-toggle="modal" data-target="#labAssignModal" class="dropdown-item">Lab Assign
                             </button>
                             <button data-toggle="modal" data-target="#refundPaymentModal" class="dropdown-item">Refund
                               Payment
                             </button>
                             <button data-toggle="modal" data-target="#creditedPaymentModal" class="dropdown-item">
                               Credited Payment
                             </button>
                             <button data-toggle="modal" data-target="#labAssignmentViewModal" class="dropdown-item">Lab
                               Assignment
                             </button>
                             <button class="dropdown-item" data-toggle="modal" data-target="#invoiceModal">View Invoice
                             </button>-->
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
              <ListPagination position="right" :pagination-data="paginateLinks" @getClickedPage="applyOrderFilter"/>
            </div>
          </div>
        </div>
        <RemainingPaymentModal :single-order="order" :remaining-amount="rest_amount"
                               @getPaidAmount="updateOrderRow($event)"/>

        <DeleteAlertModal modal-name="deleteOrderModal" model-name="order" :model-id="modelIdForDelete"
                          @confirmModelDeletion="onConfirmModelDeletion"/>

        <EditOrderStatusModal :order-id="selectedOrderID" :order-no="selectedOrderNo"
                              :order-status="selectedOrderStatus" :user-types="userType"
                              @updatedData="onUpdatedData"/>

        <OrderNotesViewModal :order-notes="orderNotes"/>

        <OrderNoteCreateModal :order-note-type-options="orderNoteTypes" :selected-order-id="selectedOrderId"
                              :selected-user-id="selectedUserId" :current-user-type-options="userType"/>

        <TagAnAppointmentModal :current-user-id="selectedOrderUserId" :current-order-id="selectedOrderID"
                               @getTagAnAppointmentStatus="updateTechnician($event)"/>

        <OrderQuickViewModal :order="{...singleOrderObj}" :order-payment="{...singleOrderPayment}"/>
        <OrderRemainingPaymentRequestSendModal :order-payment="{...singleOrderPayment}"
                                               :singleOrderObj="{...singleOrderObj}"/>
        <EditPaymentStatusModal/>
        <AppointmentViewModal :appointment="{ ...appointment }"/>
        <OrderJobCreateModal @getOrderList="getOrderList" :orderListParams="getOrdersParams"
                             :order="{...singleOrderObj}"/>
      </section>
      <div class="" data-toggle="modal" data-target="#orderNotesModal"></div>
      <div class="" data-toggle="modal" data-target="#remainingPaymentModal"></div>
      <div class="" data-toggle="modal" data-target="#editOrderStatusModal"></div>
      <div class="" data-toggle="modal" data-target="#orderRemainingPaymentRequestNotifyEmailSendModal"></div>
      <div class="" data-toggle="modal" data-target="#appointmentViewModal"></div>
      <div class="" data-toggle="modal" data-target="#OrderJobCreateModal"></div>
    </template>
  </AppLayout>
</template>

<script>
import AppLayout from '@/layouts/backEnd/AppLayout.vue'
import DeleteAlertModal from "@/components/backEnd/modal/DeleteAlertModal";
import EditOrderStatusModal from './includes/EditOrderStatusModal/EditOrderStatusModal.vue';
import EditPaymentStatusModal from './includes/EditPaymentStatusModal/EditPaymentStatusModal.vue';
import {DatePicker} from "v-calendar";
import {mapActions, mapGetters} from 'vuex';
import Loader from "@/components/backEnd/mixins/Loader";
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import ListPagination from "@/components/backEnd/pagination/ListPagination";
import RemainingPaymentModal from "@/views/backEnd/Orders/includes/RemainingPaymentModal";
import TagAnAppointmentModal from "@/views/backEnd/Orders/AllOrder/includes/TagAnAppointmentModal";
import OrderQuickViewModal from "@/views/backEnd/OrderQuickViewModal";
import OrderNotesViewModal from "@/views/backEnd/Orders/includes/OrderNoteListModal";
import OrderNoteCreateModal from "@/views/backEnd/Orders/includes/OrderNoteCreateModal";
import Authorization from "@/components/backEnd/mixins/Authorization";
import OrderRemainingPaymentRequestSendModal
  from './includes/OrderRemainingPaymentRequestSendModal/OrderRemainingPaymentRequestSendModal.vue';
import AppointmentViewModal from "@/views/backEnd/appointments/appointmentList/includes/AppointmentViewModal";
import OrderJobCreateModal from "@/views/backEnd/Orders/AllOrder/includes/OrderJobCreateModal.vue";


export default {
  name: "AllOrder",
  mixins: [ShowToastMessage, Loader, Authorization],
  components: {
    AppointmentViewModal,
    ListPagination,
    DatePicker,
    AppLayout,
    DeleteAlertModal,
    EditOrderStatusModal,
    EditPaymentStatusModal,
    RemainingPaymentModal,
    TagAnAppointmentModal,
    OrderQuickViewModal,
    OrderNotesViewModal,
    OrderNoteCreateModal,
    OrderRemainingPaymentRequestSendModal,
    OrderJobCreateModal
  },
  data() {
    return {
      selectedTechnician: '',
      order_date_form: '',
      order_date_to: '',
      delivery_date_form: '',
      delivery_date_to: '',
      orderId: null,
      delivery_date: '',
      order_date: {
        start: '',
        end: ''
      },
      getOrdersParams: {
        status: '',
        order_no: '',
        where_has_user_search_query: '',
        where_has_appointment_technician_id: '',
        order_date: [],
        delivery_date: [],
        with_relation: [
          'orderJob',
          'user',
          'user.customer.address',
          'user.customer.address.business',
          'orderCharges',
          'orderPayments.payment.cardPayment',
          'orderPaymentsByReference.cardPayment',
          'appointment.technicianAppointment.technician.user',
          'shippingAddress.business',
          'billingAddress.business',
          'orderNotes',
          'orderProductItems.productItem.productItemTransaction',
          'orderProductItems.productItem.product',
          'orderRefundsByReference',
          'appointment.technicianAppointment.technician.franchiseeTechnician', // check order tag appointment by franchisee technician
          'orderCreator.user.franchisee', // check franchisee create an order
          'appointment.appointmentTimeLogs',
        ],
        order_by_id: 'DESC',
        paginate: 1,
        pagination: '',
        page: ''
      },

      stateValue: {
        name: 'Any',
        language: 'Any'
      },
      state: [
        {
          name: 'NSW',
          language: 'NSW'
        },
        {
          name: 'VIC',
          language: 'VIC'
        },
        {
          name: 'WA',
          language: 'WA'
        },
        {
          name: 'SA',
          language: 'SA'
        },
        {
          name: 'Any',
          language: 'Any'
        },

      ],
      customerTypeSelectedValue: '',
      statusSelectedValue: '',
      currentGeekDetails: {
        id: "",
        name: "",
        address: "",
        type: "",
        phone: "",
        email: "",
        status: "",

      },

      payments: {},
      order: {},
      rest_amount: '',
      selectedOrderID: '',
      selectedOrderNo: '',
      selectedOrderUserId: '',
      singleOrderObj: {},
      singleOrderPayment: {},
      selectedOrderStatus: '',
      selectedOrderStatusForFilter: "",
      modelIdForDelete: '',
      getSettingParams: {
        type: ['default', 'order', 'payment'],
        key: [
          'default_pagination',
          'default_customer_type',
          'order_status',
          'order_note_type',
          'payment_card_surcharge',
          'order_job_status'
        ],
      },
      orderNotes: {},
      getOrderNotesParams: {
        order_id: '',
        with_relation: [
          "user",
          "order",
        ],
      },
      selectedOrderId: '',
      selectedUserId: '',
      orderChargesDetails: {},
      refunds: {},
      getUserTechniciansParams: {
        where_has_technician_status: 1,
        with_relation: ['technician'],
        order_by_first_name: "ASC",
      },
    }

  },
  watch: {
    order_date_form(value) {
      this.getOrdersParams.order_date[0] = value;
    },
    order_date_to(value) {
      this.getOrdersParams.order_date[1] = value;
    },
    delivery_date_form(value) {
      this.getOrdersParams.delivery_date[0] = value;
    },
    delivery_date_to(value) {
      this.getOrdersParams.delivery_date[1] = value;
    },
    selectedOrderStatusForFilter(value) {
      this.getOrdersParams.status = value?.value ?? '';
    },
    selectedTechnician(value) {
      this.getOrdersParams.where_has_appointment_technician_id = value?.value ?? '';
    }
  },
  computed: {
    ...mapGetters({
      previousRoute: 'previousRoute',
      paginateLinks: 'appOrders/paginateLinks',
      userType: 'appUsers/userType',
      authFranchisee: 'appAuthLogin/authFranchisee',
      orders: 'appOrders/orders',
      appointment: 'appAppointments/appointment',
      settingOrderStatus: 'appSettings/settingOrderStatus',
    }),
    orderNoteTypes() {
      return this.$store.getters["appSettings/settingOrderNoteType"].value;
    },
    currentFranchiseeId() {
      return this.authFranchisee.id
    },
    orderStatusList() {
      return [{name: 'Any', value: ''}, ...this.settingOrderStatus.value]
    },
    technicianOptions() {
      let technicians = this.$store.getters['appUsers/users'].map(item => {
        let technicianId = item.technician && item.technician.id ? item.technician.id : '';
        let firstName = item.first_name;
        let lastName = item.last_name;
        let fullName = firstName;
        fullName += firstName ? ` ${lastName}` : lastName;
        return {
          value: technicianId,
          name: fullName,
        };
      })
      return [{value: '', name: "Any"}, ...technicians];
    },
  },

  methods: {
    ...mapActions({
      getUsers: 'appUsers/getUsers',
      getSettings: 'appSettings/getSettings',
      getUserType: 'appUsers/getUserType',
      getOrders: 'appOrders/getOrders',
      getOrder: 'appOrders/getOrder',
      getAppointment: "appAppointments/getAppointment",
      getOrderNotes: 'appOrderNotes/getOrderNotes',

      deleteOrderOnList: 'appOrders/deleteOrderOnList',

      resetOrders: 'appOrders/resetOrders',
    }),

    async copyToOrderJobHandler(orderId) {
      this.singleOrderObj = {};
      this.singleOrderObj = this.orders.find(order => order.id === orderId);
      this.$nextTick(() => {
        document.querySelector(`[data-target="#OrderJobCreateModal"]`).click();
      });

    },

    async getUserTechnicianList() {
      await this.getUsers(this.getUserTechniciansParams).then(async response => {
        if (response.status !== 200) {
          await this.showToastMessage(response.message);
        }
      });
    },
    async getSingleOrderInvoice(orderUUId) {
      const routeData = this.$router.resolve({
        name: 'appOrderInvoice',
        params: {
          uuid: orderUUId
        }
      });
      window.open(routeData.href, '_blank');
    },
    async getSingleOrderUpdate(orderId) {
      const routeData = this.$router.resolve({
        name: 'appOrderUpdateProcess',
        params: {
          id: orderId
        }
      });
      window.open(routeData.href, '_blank');
    },

    async toggleModal(modalName) {
      document.querySelector(`[data-target="#${modalName}"]`).click(); // modal close
    },

    isEmptyObject(value) {
      return Object.keys(value).length === 0 && value.constructor === Object;
    },

    async addNote(orderId, userId) {
      this.selectedOrderId = orderId;
      this.selectedUserId = userId;
    },

    async getOrderNoteList(id) {
      this.loader(true);
      this.getOrderNotesParams.order_id = id;
      await this.getOrderNotes(this.getOrderNotesParams).then((response) => {
        this.loader(false);
        if (response && response.status && response.status === 200) {
          this.orderNotes = this.$store.getters['appOrderNotes/orderNotes'];
          document.querySelector('[data-target="#orderNotesModal"]').click();
          /* let ele = document.createElement('div');
           ele.dataset.toggle = 'modal';
           ele.dataset.target = '#orderNotesModal'
           ele.click();*/
        }

        if (response && response.message && response.status !== 200) {
          this.showToastMessage(response);
        }
      });
    },

    onUpdatedData(updateData) {
      let {orderId, status} = updateData;
      let index = this.orders.findIndex((order) => order.id === orderId);
      /*  let child = document.querySelector(`tbody tr:nth-child(${index+1})`);
        child.classList.add("bg-success");
        console.log(child);*/
      if (index !== -1) {
        this.orders[index].status = status;
      }
    },

    async getSettingList(settingParams) {
      await this.getSettings(settingParams);
    },

    async getCurrentUserTypes(id) {
      await this.getUserType(id);
    },

    async deleteSingleOrderOnLIst(id) {
      this.deleteOrderOnList(id).then(async (response) => {
        if (response.status === 200 || response.status === 204) {
          this.showToastMessage({type: 'success', message: 'Order deleted successfully'});
          return;
        }
        this.showToastMessage(response);
      });
    },

    async onConfirmModelDeletion(confirmModelDeletion) {
      await this.deleteSingleOrderOnLIst(confirmModelDeletion.modelId);
    },

    async getSingleOrderFromOrderObj(orderId) {
      this.singleOrderObj = await this.orders.find(({id}) => id === orderId);
      this.singleOrderPayment = await this.payments[orderId];
    },

    getDisplayDate(date) {
      let newDate = date ? new Date(date) : new Date;
      return new Date(newDate).toLocaleDateString("en-AU", {day: "numeric", month: "short", year: "numeric",})
    },

    async updateTechnician(tagStatus) {
      await this.getSingleOrder(tagStatus.order_id);
      let index = this.orders.findIndex((order) => order.id === tagStatus.order_id);
      if (index !== -1) {
        this.orders[index] = this.order;
      }
    },

    tagAnAppointment(order) {
      this.selectedOrderID = order.id
      this.selectedOrderUserId = order.user.id;
    },

    getFullName(obj) {
      if (typeof obj === 'object' && obj !== null) {
        return `${obj.first_name}  ${obj.last_name}`;
      }
      return null;
    },

    userFullAddress(customerObj) {
      let businessName = customerObj?.address?.business?.name ?? '';
      let addressStreet = customerObj?.address?.street ?? '';
      let addressSuburb = customerObj?.address?.suburb ?? '';
      let addressState = customerObj?.address?.state.toUpperCase() ?? '';
      let addressPostCode = customerObj?.address?.post_code ?? '';
      let addressCountry = customerObj?.address?.country ?? '';
      return customerObj?.type === 'Business' ?
          `${businessName} (${addressStreet}, ${addressSuburb} ${addressState} ${addressPostCode}, ${addressCountry})` :
          `${addressStreet}, ${addressSuburb} ${addressState} ${addressPostCode}, ${addressCountry}`;
    },

    updateOrderRow(paidAmount) {
      //console.log(paidAmount)
      this.payments[this.selectedOrderID].paid += paidAmount.total_amount;
      this.payments[this.selectedOrderID].remaining -= paidAmount.total_amount;
      this.payments[this.selectedOrderID].cardCharges += paidAmount.surcharge_amount;
    },

    async getOrderList(paramsObj) {
      this.loader(true);
      await this.getOrders(paramsObj)
          .then((response) => {
            if (response && response.message && response.status !== 200) {
              this.loader(false);
              this.showToastMessage(response);
            } else {
              this.getOrderCalculation();
              this.loader(false);
            }
          });
    },

    async getSingleOrder(id, modalName) {

      this.selectedOrderID = '';
      this.selectedOrderStatus = '';
      this.selectedOrderNo = '';
      this.selectedOrderID = id;
      const params = {
        id: id,
        params: {
          with_relation: [
            'user',
            'orderCharges',
            'orderPayments.payment',
            'orderPaymentsByReference',
            'appointment.technicianAppointment.technician.user'
          ]
        },
      };
      this.loader(true);
      this.rest_amount = this.payments[id].remaining;
      await this.getOrder(params)
          .then((response) => {
            if (response && response.status && response.status === 200) {
              this.loader(false);
              this.order = this.$store.getters['appOrders/order'];
              this.selectedOrderStatus = this.order.status;
              this.selectedOrderNo = this.order.order_no;
              if (modalName === 'editOrderStatusModal' && ((this.order?.status === 'Delivered' || this.order?.status === 'Closed' || this.order?.status === 'Cancelled' || this.order?.status === 'Credited' || this.order?.status === 'Refund'))) {
                this.showToastMessage({
                  type: 'error',
                  message: `You can't update it because this order status is ${this.order.status}`
                });
                return;

              }
              this.toggleModal(modalName);

            } else {
              this.loader(false);
              this.showToastMessage(response);
            }
          });
    },

    async applyOrderFilter(pageNumber) {
      this.getOrdersParams.page = pageNumber;
      await this.getOrderList(this.getOrdersParams);
    },

    getOrderCalculation() {
      this.orders.forEach((order) => {
        order.details = JSON.parse(order.details);
        let orderCharge = this.calculateOrderCharges(order);
        let orderPayment = this.calculateOrderPayments(order);
        let orderChargesDetails = this.getOrderChargesDetails(order).sort((a, b) => a.sorting - b.sorting);
        let orderRefund = this.calculateOrderRefunds(order);
        let cardCharges = this.calculateCardCharges(order);

        let remaining = orderCharge - orderPayment;
        this.payments[order.id] = {
          total: orderCharge,
          paid: orderPayment,
          remaining: remaining,
          cardCharges: cardCharges,
          chargesDetails: orderChargesDetails
        };
        this.refunds[order.id] = {
          status: orderRefund.approved_status,
          amount: orderRefund.amount
        }
        order.time_logs =  this.getOrderAppointmentTimeLogs(order);
      });
    },
    getOrderAppointmentTimeLogs(singleOrder){
     return singleOrder?.appointment?.appointmentTimeLogs?.filter(({remark, is_submitted}) =>
          remark != null && is_submitted === 'Yes').sort((a,b) => (new Date(a.created_at) - new Date(b.created_at))) ?? [];
    },

    calculateCardCharges(singleOrder) {
      return singleOrder?.orderPaymentsByReference?.filter((order) => order.type === 'Card')
          .reduce((acc, cur) => acc + cur?.cardPayment?.card_surcharge ?? 0, 0) ?? 0;
    },

    calculateOrderCharges(singleOrder) {
      const decreaseType = ['Discount', 'Coupon']
      if (singleOrder.status === 'Pending') {
        return singleOrder?.details?.grand_total ?? 0;
      } else {
        return singleOrder.orderCharges.reduce(function (accu, cur) {
          accu = (decreaseType.includes(cur.type)) ? accu - cur.amount : accu + cur.amount
          return accu
        }, 0);
      }

    },

    calculateOrderPayments(singleOrder) {
      if (singleOrder.status === 'Pending') {
        return singleOrder?.orderPaymentsByReference?.reduce(function (acc, cur) {
          return acc + cur?.total_transaction ?? 0
        }, 0) ?? 0;
      } else {
        return singleOrder?.orderPayments.reduce(function (accu, cur) {
          return (!('payment' in cur) || cur.payment === null) ? 0 : accu + cur?.payment?.total_transaction ?? 0
        }, 0);
      }

    },
    calculateOrderRefunds(singleOrder) {
      if (singleOrder.orderRefundsByReference.length > 0) {
        const amount = singleOrder?.orderRefundsByReference.filter((order) => order.status === 'Refunded').reduce((acc, cur) => acc + cur.amount, 0);
        return {approved_status: true, amount: amount};
      }
      return {approved_status: false, amount: 0};
    },

    getOrderChargesDetails(singleOrder) {
      /* const chargesArray = [
         'gst_charge',
         'order_delivery_charge',
         'applied_discount',
         'coupon_discount',
         'credited_payment_discount',
       ];

       return chargesArray.filter((chargeName) =>
           chargeName in singleOrder.details && singleOrder.details[chargeName].applied_status === true).map((chargeName) => {
         return {name: chargeName, amount: singleOrder.details[chargeName].amount}
       });*/
      let orderItemsPrice = 0, orderGST = 0, orderDiscount = 0, orderSurCharge = 0, orderDeliveryCharge = 0,
          discountDetails = [];
      let appliedDiscount = 0, couponDiscount = 0, creditedPaymentDiscount = 0;


      if (singleOrder.status === 'Pending') {
        orderGST = singleOrder.details['gst_charge'].applied_status === true ? singleOrder.details['gst_charge'].amount : 0;
        orderDeliveryCharge = singleOrder.details['order_delivery_charge'].applied_status === true ? singleOrder.details['order_delivery_charge'].amount : 0;
        appliedDiscount = singleOrder.details['applied_discount'].applied_status === true ? singleOrder.details['applied_discount'].amount : 0;
        couponDiscount = singleOrder.details['coupon_discount'].applied_status === true ? singleOrder.details['coupon_discount'].amount : 0;
        creditedPaymentDiscount = singleOrder.details['credited_payment_discount'].applied_status === true ? singleOrder.details['credited_payment_discount'].amount : 0;
        orderDiscount = appliedDiscount + couponDiscount + creditedPaymentDiscount;
        orderItemsPrice = singleOrder.details['subtotal_amount'] ?? 0;
        orderSurCharge = singleOrder.details['surcharge'] ?? 0
        discountDetails = [
          {name: 'applied discount', amount: appliedDiscount},
          {name: 'coupon discount', amount: couponDiscount},
          {name: 'credited payment discount', amount: creditedPaymentDiscount},
        ]
      } else {
        singleOrder.orderCharges.forEach((charge) => {
          if (charge.type === 'GST') {
            orderGST = orderGST + charge?.amount ?? 0
          }
          if (charge.type === 'Surcharge') {
            orderSurCharge = orderSurCharge + charge?.amount ?? 0
          }
          if (charge.type === 'Items Price') {
            orderItemsPrice = orderItemsPrice + charge?.amount ?? 0
          }
          if (charge.type === 'Delivery Charge') {
            orderDeliveryCharge = orderDeliveryCharge + charge?.amount ?? 0
          }
          if (charge.type === 'Discount') {
            orderDiscount = orderDiscount + charge?.amount ?? 0
            discountDetails.unshift({name: charge.name, amount: charge.amount})
          }
        });
      }

      return [
        {name: 'items price', sign: '+', amount: orderItemsPrice, sorting: 0},
        {name: 'surcharge', sign: '+', amount: orderSurCharge, sorting: 1},
        {
          name: 'discount', sign: '-', amount: orderDiscount, sorting: 3,
          details: discountDetails
        },
        {name: 'GST', sign: '+', amount: orderGST, sorting: 2},
        {name: 'delivery charge', sign: '+', amount: orderDeliveryCharge, sorting: 4},
      ]

    },

    sendPaymentRequestEmailToCustomerModalOpenHandler(id) {
      this.getSingleOrderFromOrderObj(id);
      document.querySelector('[data-target="#orderRemainingPaymentRequestNotifyEmailSendModal"]').click();
    },

    async openAppointmentDetailsModal(appointmentId) {
      await this.getSingleAppointment(appointmentId);
    },

    async getSingleAppointment(appointmentId) {
      let paramObj = {
        id: appointmentId,
        params: {
          with_relation: ["customer.user", "customer.address", "billingAddress", "service", "address",
            "appointmentCharges", "appointmentNotes", "appointmentCreator", "appointmentConsents",
            "appointmentPayments.payment", "technicianAppointment.technician.user", "appointmentRefundsByReference"
          ],
        },
      };
      this.loader(true);
      const response = await this.getAppointment(paramObj);
      this.loader(false);
      if (response?.message) {
        this.showToastMessage(response);
      }
      if (response.status === 200) {
        document.querySelector('[data-target="#appointmentViewModal"]').click();
      }

    },
  },
  async beforeMount() {
    await this.resetOrders();
  },

  async mounted() {
    await this.loader(true);
    await this.getSettingList(this.getSettingParams);
    const currentUserId = this.$store.getters["appAuthLogin/authUser"].id;
    const franchiseeId = this.$store.getters["appAuthLogin/authFranchisee"].id;
    if (franchiseeId !== null) {
      this.getOrdersParams.where_has_franchisee_throw_appointment_technician_or_creator = franchiseeId
    }
    await this.getCurrentUserTypes(currentUserId);
    if (this.$route.params.orderNo) {
      this.getOrdersParams.order_no = this.$route.params.orderNo;
    }
    await this.getUserTechnicianList();
    await this.getOrderList(this.getOrdersParams);
    await this.loader(false);
    // await this.getOrderChargesDetails();
  }

}
</script>

<style scoped>
.table-responsive {
  min-height: 80vh;
}

.view-edit-delte-icon {
  color: #B3C0CE;
  transition: all 0.2s;
}

.view-edit-delte-icon:hover {
  color: #5A8DEE;
}

.badge {
  display: inline-block;
  padding: 4px 8px !important;
  font-size: 10px !important;
  font-weight: 400;
  line-height: 1 !important;
  text-align: center !important;
  white-space: nowrap !important;
  vertical-align: baseline !important;
  border-radius: 0.375rem !important;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media only screen and (max-width: 1337px) and (min-width: 320px) {
  .table {
    white-space: nowrap !important;
  }

}
</style>

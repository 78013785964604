<template>
  <div class="modal fade" id="OrderJobCreateModal" tabindex="-1" role="dialog" aria-labelledby="OrderJobCreateModal"
       aria-hidden="true">
    <div class="modal-dialog  modal-md modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header bg-primary">
          <h1 class="modal-title white">Copy To Job</h1>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <i class="bx bx-x"></i>
          </button>
        </div>
        <div class="modal-body">
          <div>
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label>Assign To</label>
                  <VueMultiselect v-model="selectedAssigne" class="" placeholder="Select assignee"
                                  :options="assigneOptions" :close-on-select="true" label="name" track-by="value"
                                  :show-labels="false" :allow-empty="false"/>
                </div>
              </div>
              <div class="col-12">
                <div class="form-group">
                  <div class="controls">
                    <label>Add Note</label>
                    <div style="margin-top: .5rem">
                      <div class="custom-control custom-switch custom-control-inline mb-1 d-flex align-items-center">
                        <div>
                          <input v-model="haveANote" type="checkbox" class="custom-control-input" checked
                                 id="haveAppointmentNote">
                          <label class="custom-control-label mr-1" for="haveAppointmentNote"></label>
                        </div>
                        <span class="font-medium-1">{{ haveANote ? "Yes" : "No" }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12" v-if="haveANote">
                <div class="form-group">
                  <label>Note </label>
                  <textarea v-model="note" class="form-control" rows="3" placeholder="Note"></textarea>
                </div>
              </div>
              <div class="col-12 form-group" v-if="userType && userType?.length > 1 && haveANote">
                <label>User Type</label>
                <VueMultiselect v-model="selectedUserType" class="" :allow-empty="false" :options="userType"
                                :close-on-select="true" placeholder="Select type" label="name" track-by="value"
                                :show-labels="false"/>

              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer border-0 pt-0">
          <button type="button" class="btn btn-primary" @click="createJobHandler">
            <i class="bx bx-x d-block d-sm-none"></i>
            <span class="d-none d-sm-block">Create job</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  mapActions,
  mapGetters
} from "vuex";
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Loader from "@/components/backEnd/mixins/Loader";

export default {
  name: "OrderJobCreateModal",
  mixins: [ShowToastMessage, Loader],
  props: ['order', 'orderListParams'],
  emits: ['getOrderList'],
  data() {
    return {
      currentOrder: {},
      selectedAssigne: null,
      note: '',
      selectedUserType: null,
      haveANote: false
    }
  },
  computed: {
    ...mapGetters({
      userType: 'appUsers/userType',
      settingOrderJobStatus: 'appSettings/settingOrderJobStatus',
      orderJob: 'appOrderJobs/orderJob',

    }),
    assigneOptions() {
      return this.$store.getters['appUsers/users'].map(item => {
        let technicianUserId = item.id;
        let firstName = item.first_name;
        let lastName = item.last_name;
        let fullName = firstName;
        fullName += firstName ? ` ${lastName}` : lastName;
        return {
          value: technicianUserId,
          name: fullName,
        };
      })

    },
  },
  watch: {
    order(currentOrder) {
      this.selectedAssigne = null;
      this.note = '';
      this.currentOrder = currentOrder;
      this.haveANote = false;
      this.selectedUserType = null;

    }
  },
  methods: {
    ...mapActions({
      postOrderJob: 'appOrderJobs/postOrderJob',
      postOrderJobNote: 'appOrderJobNotes/postOrderJobNote',

    }),

    async createJobHandler() {
      if (!this.selectedAssigne?.name) {
        this.showToastMessage({
          type: 'warning',
          message: 'Please select assignee'
        });
        return;
      }
      if (this.haveANote && !this.note.trim()) {
        this.showToastMessage({
          type: 'warning',
          message: 'Please enter note'
        });
        return;
      }
      if (this.haveANote && this.userType?.length > 1 && !this.selectedUserType?.name) {
        this.showToastMessage({
          type: 'warning',
          message: 'Please select user type'
        });
        return;
      }
      const data = {
        customer_id: this.currentOrder?.user?.customer?.id,
        order_id: this.currentOrder.id,
        assigned_to: this.selectedAssigne.value,
        order_no: this.currentOrder.order_no,
      }
      this.loader(true);
      const response = await this.postOrderJob(data);
      this.loader(false);

      if (response.message) {
        this.showToastMessage(response);
      }
      if (response?.status === 201 && this.orderJob?.id) {

        if (this.haveANote) {
          this.loader(true);
          const orderJobNoteData = {
            order_job_id: this.orderJob?.id,
            note: this.note,
            user_type: this.userType?.length === 1 ? this.userType[0].value : this.selectedUserType.value,
            user_id: this.$store.getters["appAuthLogin/authUser"].id,
          }
          await this.postOrderJobNote(orderJobNoteData);


        }
        this.showToastMessage({
          type: 'success',
          message: 'Job created successfully'
        });
        this.loader(false);
        this.$emit('getOrderList', this.orderListParams);
        document.querySelector('[data-target="#OrderJobCreateModal"]').click();
        this.resetData();

      }

    },
    resetData() {
      this.selectedAssigne = null;
      this.note = '';
      this.currentOrder = {};
      this.haveANote = false;
      this.selectedUserType = null;
    }

  }

}
</script>

<style>

</style>

<template>
  <div class="modal fade" id="orderNotesModal" tabindex="-1" role="dialog" aria-labelledby="orderNotesModal"
       aria-hidden="true">
    <div class="modal-dialog modal-md modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header bg-primary">
          <h1 class="modal-title white">Notes</h1>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <i class="bx bx-x"></i>
          </button>
        </div>
        <div class="modal-body">
          <div>
            <div class="row d-flex justify-content-center">

              <div class="col-12 col-md-12" v-for="(orderNote,index) in orderNotes" :key="index">
                <div class="d-flex ">
                  <div class="" style="flex-shrink: 0">
                    <img class="rounded-avatar" :src="orderNote.user.avatar" alt="">
                  </div>
                  <div class="ml-1">
                    <h5 class="list-title mb-0 font-weight-bold">
                      {{ orderNote.user.first_name + " " + orderNote.user.last_name }} <small
                        class="text-italic font-weight-normal">as {{ orderNote.user_type }}</small></h5>

                    <span
                        class=" font-weight-bold mb-1 badge badge-circle-primary font-small-1">{{
                        orderNote.type
                      }}</span>
                    <p class="font-small-3">{{ orderNote.description }}</p>
                  </div>
                </div>
                <hr>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer border-0 pt-0">
          <button type="button" class="btn btn-primary" data-dismiss="modal">
            <i class="bx bx-x d-block d-sm-none"></i>
            <span class="d-none d-sm-block">Close</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "OrderNoteListModal",
  props: ['orderNotes'],
  data() {
    return {};
  },
  watch: {},
  methods: {},
  computed: {},

};
</script>

<style scoped>
.rounded-avatar {
  overflow: hidden;
  width: 60px;
  border-radius: 50% !important;
  border-radius: 50% !important;
  border: 2px solid #7698d9 !important;
  border-bottom: none !important;
  border-left: none !important;
  /* border-top: none  !important; */
}
</style>

<template>
<div class="modal fade " :id="modalName" :aria-labelledby="modalName" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-dialog-scrollable modal-sm modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header bg-primary">
                <h5 class="text-white mb-0 pb-0">{{modalTitle}} Details </h5>
                <button type="button" class="close" @click="closeModal">
                    <i class="bx bx-x"></i>
                </button>
            </div>
            <div class="modal-body px-0">
                <ul>
                    <li v-for="(charge,index) in discounts" :key="index">
                        <span class="text-capitalize">{{charge.name}} : {{parseFloat(charge.amount/100).toFixed(2)}}</span>
                    </li>
                </ul>
                <p class="text-center" v-if="discounts.length === 0"> No {{modalTitle}} applied</p>

            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: "AppointmentChargesDetailsModal",
    props: ['appointmentCharges','modalName', 'modalTitle'],
    data() {
        return {
            discounts: [],
        }
    },
    watch: {
        appointmentCharges(chargeData) {
            this.discounts = chargeData;
        },
    },
    methods: {
        closeModal() {
            document.querySelector(`[data-target='#${this.modalName}']`).click();
        }
    },

}
</script>

<style>

</style>

<template>
  <div class="modal fade" id="tagAnAppointmentModal" tabindex="-1" role="dialog"
       aria-labelledby="tagAnAppointmentModal"
       aria-hidden="true">
    <div class="modal-dialog  modal-md modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header bg-primary">
          <h1 class="modal-title white">Tag Appointment</h1>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <i class="bx bx-x"></i>
          </button>
        </div>
        <div class="modal-body">
          <div>
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <div class="controls">
                    <label for="appointment_code">Appointment Code</label>
                    <input v-model="appointment_reference" id="appointment_code" type="text" class="form-control"
                           placeholder="" name="appointment_code">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer border-0 pt-0">
          <button type="button" class="btn btn-primary" @click="tagAppointment">
            <i class="bx bx-x d-block d-sm-none"></i>
            <span class="d-none d-sm-block">Add</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions} from "vuex";
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Loader from "@/components/backEnd/mixins/Loader";

export default {
  name: "TagAnAppointmentModal",
  mixins: [ShowToastMessage, Loader],
  props: ['currentUserId', 'currentOrderId'],
  emits: ['getTagAnAppointmentStatus'],
  data() {
    return {
      appointment_reference: '',
      getAppointmentParams: {
        reference: '',
        with_relation: ['order', 'customer.user']
      },
    }
  },
  computed: {
    userId() {
      return this.currentUserId;
    },
    orderId() {
      return this.currentOrderId;
    }
  },
  methods: {
    ...mapActions({
      getAppointments: 'appAppointments/getAppointments',
      updateAppointment: 'appAppointments/putAppointment'
    }),
    async tagAppointment() {
      const status = await this.getSingleAppointment(this.appointment_reference);
      if (status === true) {
        const appointment = this.$store.getters['appAppointments/appointments'][0] ?? null;
        if (appointment === null) {
          this.showToastMessage({type: 'warning', message: 'No Appointment Found'});
          return;
        }
        let appointmentUserId = appointment && appointment.customer && appointment.customer.user ? appointment.customer.user.id : null
        if (appointmentUserId !== null && appointmentUserId !== this.userId) {
          this.showToastMessage({type: 'warning', message: 'Appointment user not same'});
        } else if (appointmentUserId !== null && appointmentUserId === this.userId && appointment.order !== null) {
          this.showToastMessage({type: 'warning', message: 'Order already tag in'});
        } else {
          const updateStatus = await this.updateSingleAppointment(appointment.id);
          console.log(updateStatus);
          if (updateStatus === true) {
            document.querySelector('[data-target="#tagAnAppointmentModal"]').click();
            this.$emit('getTagAnAppointmentStatus', {order_id: this.orderId, status: true});
            this.showToastMessage({type: 'success', message: 'Order tag in appointment'});
          }
        }

      }
    },
    async getSingleAppointment(reference) {
      this.getAppointmentParams.reference = reference;
      this.loader(true);
      return await this.getAppointments(this.getAppointmentParams)
          .then((response) => {
            if (response && response.status && response.status === 200) {
              this.loader(false);
              return true;
            } else {
              this.showToastMessage(response);
            }
            return false;
          });
    },
    async updateSingleAppointment(appointmentId) {
      let dataObj = {
        id: appointmentId,
        data: {order_id: this.orderId},
      }
      this.loader(true);
      const response = await this.updateAppointment(dataObj);
      if (response.status === 200) {
        this.loader(false);
        return true
      } else {
        this.loader(false);
      }
      if (response.message) {
        this.showToastMessage(response);
      }
      return false
    },
  }

}
</script>

<style>

</style>

<template>
  <div id="orderRemainingPaymentRequestNotifyEmailSendModal" aria-hidden="true" aria-labelledby="orderRemainingPaymentRequestNotifyEmailSendModal" class="modal fade"
       role="dialog" tabindex="-1">
    <div class="modal-dialog modal-lg modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header bg-primary">
          <h4 class="modal-title white">Payment request email notify to customer</h4>
          <button aria-label="Close" class="close" data-dismiss="modal" type="button" >
            <i class="bx bx-x"></i>
          </button>
        </div>
        <div class="modal-body">

          <div>
            <div class="row ">
              <div class="col-12 form-group">
                <h5>Due Amount : {{(orderDetails.orderRemainingAmount/100).toFixed(2)}} $ </h5>
<!--                <VueMultiselect v-model="selectedPayableAmountType" :allow-empty="false" class="multiselect-blue" :options="allAppointmentStatus" :close-on-select="true" placeholder="Select a status" label="name" track-by="value" :show-labels="false" />-->

              </div>
              <div class="col-12 form-group">
                <label>Payable Amount Type </label>
<!--                <VueMultiselect v-model="selectedPayableAmountType" :allow-empty="false" class="multiselect-blue" :options="allAppointmentStatus" :close-on-select="true" placeholder="Select a status" label="name" track-by="value" :show-labels="false" />-->
                <VueMultiselect v-model="selectedPayableAmountType" :allow-empty="false" :close-on-select="true" :options="payableAmountTypes"
                                :show-labels="false" class="" label="name" placeholder="Select type"
                                track-by="value"/>

              </div>
              <div class="col-12" >
                <h6>Customer will get a email of payable amount : {{(customerPayableAmount/100).toFixed(2)}} $</h6>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer border-0 pt-0">
          <button class="btn btn-primary" type="button" @click="sendEmailHandler">
            <i class="bx bx-x d-block d-sm-none"></i>
            <span class="d-none d-sm-block">Send Email</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Loader from "@/components/backEnd/mixins/Loader";
import {mapActions} from "vuex";


export default {
  name: "AppointmentRemainingPaymentRequestSendModal",
  props: ['orderPayment','singleOrderObj'],
  mixins: [ShowToastMessage, Loader],
  components: {},
  data() {
    return {

      payableAmountTypes:[

        {
          name:'Half',
          value:1
        },
        {
          name:'Quarter',
          value:2
        },
        {
          name:'Full',
          value:4
        },
        {
          name:'Custom',
          value:3
        },

      ],
      selectedPayableAmountType:{
        name:'None',
        value:''
      },
      currentAppointmentCharges: [],
      orderDetails: {
        id: '',
       

       orderRemainingAmount:0

      }
    }
  },
  watch: {
    orderPayment(currentOrderPayment) {
      if (currentOrderPayment){
        this.selectedPayableAmountType = {
          name:'None',
          value:''
        };
        // this.orderDetails.id = currentAppointment.id ? currentAppointment.id : '';
        this.orderDetails.orderRemainingAmount = currentOrderPayment?.remaining ?? 0;
      }
    },
     singleOrderObj(order) {
      this.orderDetails.id = order?.id ?? "";
    }

  },
  computed: {
    customerPayableAmount(){
      if (this.selectedPayableAmountType.name !== 'None'){
        switch (this.selectedPayableAmountType.value) {
            case 1:
              return ((this.orderDetails.orderRemainingAmount) / 2).toFixed(2);
            case 2:
              return ((this.orderDetails.orderRemainingAmount) / 4).toFixed(2);
            case 4:
              return ((this.orderDetails.orderRemainingAmount)).toFixed(2);
            case 3:
              return ((this.orderDetails.orderRemainingAmount)).toFixed(2);
        }

      }
      return ((this.orderDetails.orderRemainingAmount)).toFixed(2);

    }


  },
  methods: {
    ...mapActions({
      postOrderPaymentRequestNotifyEmail:'appOrders/postOrderPaymentRequestNotifyEmail',

    }),
   async sendEmailHandler() {
      if (this.selectedPayableAmountType?.name === 'None'){
        this.showToastMessage({type:'error',message:'Please enter a payable amount type'});
        return;
      }
      this.loader(true);
      let paramObj = {
        id: this.orderDetails.id,
        data: {
          amount_type: this.selectedPayableAmountType.value.toString(),

        }
      }
      const response = await this.postOrderPaymentRequestNotifyEmail(paramObj);
      this.loader(false);
      if (response.message) {
        this.showToastMessage(response);
      }
      if (response.status === 204){
        document.querySelector('[data-target="#orderRemainingPaymentRequestNotifyEmailSendModal"]').click();
        this.showToastMessage({type:'success',message:' Email sent successfully !'});
      }

    }
  },

}
</script>

<style>

</style>

<template>
  <div class="modal fade " id="editOrderStatusModal" tabindex="-1" role="dialog" aria-labelledby="editOrderStatusModal"
       aria-hidden="true">
    <div class="modal-dialog  modal-md modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header bg-primary">
          <h1 class="modal-title white">Change Order Status</h1>
          <button @click="resetData" type="button" class="close" data-dismiss="modal" aria-label="Close">
            <i class="bx bx-x"></i>
          </button>
        </div>
        <div class="modal-body">
          <div>
            <div class="row">
              <div class="col-12">
                <h4 class="text-dark ">Status</h4>
                <div class="form-group">
                  <div class="controls">
                    <VueMultiselect :allow-empty="false" v-model="selectedOrderStatus" class="multiselect-blue"
                                    :options="filteredStatusOptions"
                                    :close-on-select="true" label="name" track-by="value" :show-labels="false"/>
                    <div class="text-danger" v-if="errors.status">{{ errors.status }}</div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row" v-if="isOrderStatusChange">
              <div class="col-12">
                <div class="form-group">
                  <div class="controls">
                    <label>Add Note</label>
                    <div style="margin-top: .5rem">
                      <div class="custom-control custom-switch custom-control-inline mb-1 d-flex align-items-center">
                        <div>
                          <input v-model="have_note" type="checkbox" class="custom-control-input" checked
                                 id="haveAppointmentNote">
                          <label class="custom-control-label mr-1" for="haveAppointmentNote"></label>
                        </div>
                        <span class="font-medium-1">{{ have_note ? "Yes" : "No" }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row" v-if="have_note">

              <div class="col-12 form-group" v-if="isUserMultipleType > 1">
                <label>User Type</label>
                <VueMultiselect v-model="selectedUserType" class="" :allow-empty="false" :options="userTypeOptions"
                                :close-on-select="true" placeholder="Select type" label="name" track-by="value"
                                :show-labels="false"/>
                <div class="text-danger">{{ }}</div>

              </div>
              <div class="col-12">
                <div class="form-group">
                  <label>Note</label>
                  <textarea v-model="note_description" class="form-control" id="" rows="10"></textarea>
                  <div class="text-danger">{{ }}</div>
                </div>
              </div>

            </div>

           <div class="row" v-if="(selectedOrderStatus.value !== '' && have_note)">
                        <div class="col-12">
                            <div class="form-group">
                                <div class="controls">
                                    <label>Visible Note to customer</label>
                                    <div style="margin-top: .5rem">
                                        <div class="custom-control custom-switch custom-control-inline mb-1 d-flex align-items-center">
                                            <div>
                                                <input v-model="visibleNoteToCustomer" type="checkbox" class="custom-control-input" checked id="visibleNoteToCustomer">
                                                <label class="custom-control-label mr-1" for="visibleNoteToCustomer"></label>
                                            </div>
                                            <span class="font-medium-1">{{ visibleNoteToCustomer ? "Yes" : "No" }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
            </div>

            <div class="row" v-if="selectedOrderStatus.value !== ''">
              <div class="col-12">
                <div class="form-group">
                  <div class="controls">
                    <label>Email Notify To Customer</label>
                    <div style="margin-top: .5rem">
                      <div class="custom-control custom-switch custom-control-inline mb-1 d-flex align-items-center">
                        <div>
                          <input v-model="notifyToCustomer" type="checkbox" class="custom-control-input" checked id="statusUpdateNotifyToCustomer">
                          <label class="custom-control-label mr-1" for="statusUpdateNotifyToCustomer"></label>
                        </div>
                        <span class="font-medium-1">{{ notifyToCustomer ? "Yes" : "No" }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer border-0 pt-0">
          <button @click="updateOrderStatus" type="button" class="btn btn-primary">
            <i class="bx bx-x d-block d-sm-none"></i>
            <span class="d-none d-sm-block">Save </span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import Loader from "@/components/backEnd/mixins/Loader";
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";

export default {
  name: "EditOrderStatusModal",
  emits: ['updatedData'],
  mixins: [Loader, ShowToastMessage],
  props: {
    orderId: {
      type: [Number, String],
      required: true,
    },
    orderNo: {
      type: [String],
      required: true,
    },
    orderStatus: {
      type: String,
      required: true
    },
    userTypes: {
      type: Array,
      default: () => [],
      required: true,
    }
  },
  data() {
    return {
      visibleNoteToCustomer:false,
      notifyToCustomer: false,
      have_note: false,
      selectedOrderStatus: {
        name: 'None',
        value: ''
      },
      getSettingsParams: {
        type: ['order'],
        key: ['order_status'],
      },
      order: {},
      status: null,
      initialStatusData: {
        value: '',
        name: ''
      },
      putStatusData: {},
      errors: {
        status: ''
      },
      selectedUserType: {},
      note_description: '',
      isOrderStatusChange: false,

    }
  },
  computed: {
    ...mapGetters({
      previousRoute: 'previousRoute',
    }),
    orderStatusOptions() {
      return this.$store.getters['appSettings/settingOrderStatus'].value;
    },
    filteredStatusOptions() {
      let shownStatusArray = {'Pending': [0, 6, 7, 8], 'Processing': [1, 2, 6, 7, 8],'Ready to Pick':[2,3,6,7,8],'In Transit':[3,4,6,7,8]}
      let shownStatusIds = shownStatusArray[this.orderStatus] ?? [];

      return this.getOrderStatusWithList(shownStatusIds);
    },

    userTypeOptions() {
      return this.userTypes;
    },
    isUserMultipleType() {
      return this.userTypes.length;
    }
  },
  watch: {
    orderStatus(orderStatus) {
      if (orderStatus) {
        this.selectedOrderStatus = this.orderStatusOptions.find(({name}) => name.toLowerCase() === orderStatus.toLowerCase());
        // initial order status
        this.initialStatusData = {
          value: this.selectedOrderStatus.value,
          name: this.selectedOrderStatus.name
        };
        this.putStatusData = {...this.initialStatusData};
      }
    },
    selectedOrderStatus() {
      this.status = this.selectedOrderStatus.value;
      // after change  order status

      this.putStatusData = {
        value: this.selectedOrderStatus.value,
        name: this.selectedOrderStatus.name
      }
      this.orderStatusChange();
    },
    userTypes() {
      if (this.userTypes.length === 1) {
        this.selectedUserType = this.userTypes[0]
      }
    }

  },
  methods: {
    ...mapActions({
      getSettings: 'appSettings/getSettings',
      putOrderStatus: 'appOrders/putOrderStatus',
      postOrderStatusUpdateNotifyEmail: 'appOrders/postOrderStatusUpdateNotifyEmail',

      // loyalty
      postLoyaltyPointSystemUsing: 'apployaltyPoints/postLoyaltyPointSystemUsing',
    }),
    orderStatusChange() {
      this.isOrderStatusChange = !((this.initialStatusData.value === this.putStatusData.value) &&
          (this.initialStatusData.name === this.putStatusData.name));
    },
    async getSettingList(settingParams) {
      await this.getSettings(settingParams);
    },
    getOrderStatusWithList(orderStatusArray) {
      let statusValues = orderStatusArray;
      return this.orderStatusOptions.filter((item) => {
        return statusValues.includes(Number(item.value));
      });
    },
    loyaltyPointSystemUsingHandler(orderNo) {
            const data = {
                type:1,
                reference:orderNo,
            };
            this.postLoyaltyPointSystemUsing(data);
        },
    async updateOrderStatus() {
      if (this.isOrderStatusChange === false) {
        this.errors.status = 'Please Change the Status First'
        setTimeout(() => this.errors = {}, 4000);

        return
      }
      const dataObj = {
        id: this.orderId,
        data: {
          status: parseInt(this.status),
          order_note: this.note_description,
          customer_can_view:this.visibleNoteToCustomer ? 1 : 0,
          user_type: this.selectedUserType.value,
          user_id: this.$store.getters["appAuthLogin/authUser"].id,
        }
      }
      this.loader(true);
      console.log(dataObj);
      await this.putOrderStatus(dataObj).then((response) => {
        console.log(response);
        if (response.status === 200 && response.data.data.status === true) {
          if (this.status === "4") {
                        this.loyaltyPointSystemUsingHandler(this.$props.orderNo);
                        // this.customerOpinionRequestEmailNotify();
                        
            }
          document.querySelector('[data-target="#editOrderStatusModal"]').click();
          this.orderEmailNotify();
          this.$emit('updatedData', {orderId: this.orderId, status: this.selectedOrderStatus.name});
          this.loader(false);
          this.resetData();
          this.showToastMessage({
            type: 'success',
            message: 'Order info has updated.'
          });
        }
        // error goes here

        if (response.message) {
          this.showToastMessage(response);
        }
      });

    },
    orderEmailNotify() {
      const data = {
        id: this.orderId,
        params: {
          notify_customer: this.notifyToCustomer ? 1 : 0,
          notify_internal_user: 1
        }

      };

      this.postOrderStatusUpdateNotifyEmail(data);
    },
    resetData() {
      Object.assign(this.$data, this.$options.data.apply(this));
    }
  },
  async mounted() {
    await this.getSettingList(this.getSettingsParams);
  },


}
</script>

<style>

</style>

<template>
<div class="modal fade" id="orderNoteCreateModal" tabindex="-1" role="dialog" aria-labelledby="orderNoteCreateModal" aria-hidden="true">
    <div class="modal-dialog modal-md modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header bg-primary">
                <h1 class="modal-title white">Create Note</h1>
                <button @click="resetData()" type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <i class="bx bx-x"></i>
                </button>
            </div>
            <div class="modal-body">

                <div>
                    <div class="row ">
                        <div class="col-12" v-if="isUserMultipleType > 1">
                            <label>User Type</label>
                            <VueMultiselect v-model="selectedUserType" class="" :allow-empty="false" :options="currentUserTypeOptions" :close-on-select="true" placeholder="Select Type" label="name" track-by="value" :show-labels="false" />
                            <div v-if="errors.user_type !== undefined && errors.user_type.length > 0">
                                <p v-for="(error,index) in  errors.user_type" :key="index" class="text-danger mb-1">
                                    {{ error }}
                                </p>

                            </div>
                        </div>
                        <div class="col-12">
                            <label>Note Type</label>
                            <VueMultiselect v-model="selectedOrderNoteType" class="" :allow-empty="false" :options="orderNoteTypeOptions" :close-on-select="true" placeholder="Select Type" label="name" track-by="value" :show-labels="false" />
                            <div v-if="errors.type !== undefined && errors.type.length > 0">
                                <p v-for="(error,index) in  errors.type" :key="index" class="text-danger mb-1">
                                    {{ error }}
                                </p>

                            </div>
                        </div>
                        <div class="col-12">
                            <div class="form-group">
                                <label>Note</label>
                                <textarea v-model="description" class="form-control" id="" rows="10" spellcheck="true" placeholder="Notes goes here..."></textarea>
                                <div v-if="errors.description !== undefined && errors.description.length > 0">
                                    <p v-for="(error,index) in  errors.description" :key="index" class="text-danger mb-1">
                                        {{ error }}
                                    </p>

                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <div class="controls">
                                    <label>Visible Note to customer</label>
                                    <div style="margin-top: .5rem">
                                        <div class="custom-control custom-switch custom-control-inline mb-1 d-flex align-items-center">
                                            <div>
                                                <input v-model="visibleNoteToCustomer" type="checkbox" class="custom-control-input" checked id="visibleNoteToCustomer">
                                                <label class="custom-control-label mr-1" for="visibleNoteToCustomer"></label>
                                            </div>
                                            <span class="font-medium-1">{{ visibleNoteToCustomer ? "Yes" : "No" }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6" v-if="visibleNoteToCustomer">
                            <div class="form-group">
                                <div class="controls">
                                    <label>Notify customer</label>
                                    <div style="margin-top: .5rem">
                                        <div class="custom-control custom-switch custom-control-inline mb-1 d-flex align-items-center">
                                            <div>
                                                <input v-model="notifyToCustomer" type="checkbox" class="custom-control-input" checked id="notifyToCustomer">
                                                <label class="custom-control-label mr-1" for="notifyToCustomer"></label>
                                            </div>
                                            <span class="font-medium-1">{{ notifyToCustomer ? "Yes" : "No" }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div class="modal-footer border-0 pt-0">
                <button type="button" @click="createNote" class="btn btn-primary">
                    <i class="bx bx-x d-block d-sm-none"></i>
                    <span class="d-none d-sm-block">Create</span>
                </button>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {
    mapActions,
    mapGetters,

} from "vuex";
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";

export default {
    name: "OrderNoteCreateModal",
    props: {
        currentUserTypeOptions: {
            type: Array,
            default: () => [],
            required: true,
        },
        orderNoteTypeOptions: {
            type: Array,
            default: () => [],
            required: true,
        },
        selectedOrderId: {
            type: [Number, String],
            required: true,
        },
        selectedUserId: {
            type: [Number, String],
            required: true,
        }
    },
    mixins: [ShowToastMessage],
    data() {
        return {
            visibleNoteToCustomer: false,
            notifyToCustomer: false,
            description: '',
            selectedUserType: '',
            selectedOrderNoteType: '',
            errors: {},
            frontEndErrors: {
                userType: [],
                noteType: [],
                noteDescription: [],

            }
        };
    },
    watch: {
        currentUserTypeOptions() {
            if (this.currentUserTypeOptions.length === 1) {
                this.selectedUserType = this.currentUserTypeOptions[0];
            }
        },
        visibleNoteToCustomer(visibleNoteToCustomer) {
            if (!visibleNoteToCustomer) {
                this.notifyToCustomer = visibleNoteToCustomer;
            }

        },

    },
    computed: {
      
            ...mapGetters({
                orderNote: 'appOrderNotes/orderNote',
            }),
        
        isUserMultipleType() {
            return this.currentUserTypeOptions.length;
        },

    },
    methods: {
        ...mapActions({
            postOrderNotes: 'appOrderNotes/postOrderNote',
            postOrderNoteCreationNotifyEmail: 'appOrderNotes/postOrderNoteCreationNotifyEmail',

        }),
        async createNote() {

            const data = {
                order_id: this.selectedOrderId,
                user_id: this.selectedUserId,
                user_type: this.selectedUserType.value ?? '',
                type: this.selectedOrderNoteType.value ?? '',
                description: this.description
            }

            await this.postOrderNotes(data).then((response) => {
                if (response.status === 200 || response.status === 201) {
                    this.showToastMessage({
                        type: 'success',
                        message: 'Note Create Successfully'
                    });
                    this.orderNoteCreateEmailNotify({
                        id: this.orderNote.id,
                        params: {

                            notify_internal_user: 1,
                            notify_technician: 1,
                            notify_creator: 1,
                            notify_customer: this.notifyToCustomer && this.notifyToCustomer ? 1 : 0
                        }

                    })
                    document.querySelector('[data-target="#orderNoteCreateModal"]').click();
                } else {
                    this.errors = response.errors;
                }
                if (response.message) {
                    this.showToastMessage(response);
                }
                this.resetData();
            });

        },
        async orderNoteCreateEmailNotify(data) {

            await this.postOrderNoteCreationNotifyEmail(data);

        },
        resetData() {
            Object.assign(this.$data, this.$options.data.apply(this));
        }
    },

};
</script>

<style>
</style>

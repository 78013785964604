<template>
  <div class="modal fade " id="remainingPaymentModal" tabindex="-1" role="dialog"
       aria-labelledby="remainingPaymentModal"
       aria-hidden="true">
    <div class="modal-dialog  modal-xl modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header bg-primary">
          <h1 class="modal-title white">Add Order Payment</h1>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <i class="bx bx-x"></i>
          </button>
        </div>
        <div class="modal-body">

          <div class="row">
            <div class="col-lg-5 mt-1 mt-lg-0">
              <div class="card h-100">
                <div class="card-body">
                  <div v-if="singleOrder.user !== undefined">
                    <div class="d-flex justify-content-between" data-toggle="collapse" href="#customerDetails"
                         role="button">
                      <p class="text-bold-500 text-uppercase"> Customer Details</p>
                      <i class='bx bx-chevron-down'></i>
                    </div>
                    <div class="collapse collapse show" id="customerDetails">
                      <div class="form-group">
                        <div class="">
                          <span class="text-bold-500"> Name : </span>
                          <span class="text-capitalize">{{ customerFullName }}</span>
                        </div>
                      </div>
                      <div class="form-group">
                        <div class="">
                          <span class="text-bold-500"> Email : </span>
                          <span class="">{{ singleOrder.user.email }}</span>
                        </div>
                      </div>
                      <div class="form-group">
                        <div class="">
                          <span class="text-bold-500"> Phone : </span>
                          <span class="">{{ singleOrder.user.phone_number }}</span>
                        </div>
                      </div>

                    </div>
                  </div>
                  <hr>

                  <div>
                    <div class="d-flex justify-content-between" data-toggle="collapse" href="#billingInformation"
                         role="button">
                      <p class="text-bold-500 text-uppercase"> Order Details</p>
                      <i class='bx bx-chevron-down'></i>
                    </div>
                    <div class="collapse collapse show" id="billingInformation">
                      <div class="form-group">
                        <div class="">
                          <span class="text-bold-500"> Order No : </span>
                          <span class="">{{ singleOrder.order_no }}</span>
                        </div>
                      </div>
                      <div class="form-group">
                        <div class="">
                          <span class="text-bold-500"> Order Date : </span>
                          <span class="">{{ singleOrder.order_date }}</span>
                        </div>
                      </div>
                      <div class="form-group">
                        <div class="">
                          <span class="text-bold-500"> Delivery Date : </span>
                          <span class="">{{ singleOrder.delivery_date }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr>

                  <div v-if="paymentMethod === 'cardPayment' && surcharge.status">
                    <div class="d-flex justify-content-between" data-toggle="collapse" href="#cardSurchargeInformation"
                         role="button">
                      <p class="text-bold-500 text-uppercase text-warning"> Card Surcharge Details</p>
                      <i class='bx bx-chevron-down'></i>
                    </div>
                    <div class="collapse collapse show" id="cardSurchargeInformation">
                      <div v-for="surCharge in surcharge.details" :key="surCharge" class="row">
                        <div class="col-12 text-left">
                          <p class="text-bold-400 pb-0 mb-0">{{ surCharge.name }}:
                            ${{ parseFloat(surCharge.rate).toFixed(2) }} %</p>
                        </div>

                      </div>
                      <h5>Payable Surcharge</h5>
                      <div v-for="cardCharge in cardCharges" :key="cardCharge" class="row">
                        <div class="col-12 text-left">
                          <p class="text-bold-400 pb-0 mb-0">{{ cardCharge.name }}:
                            $ {{ parseFloat(cardCharge.charge / 100).toFixed(2) }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-7 mt-lg-0">
              <div class="card h-100">
                <div class="card-body">
                  <div>
                    <div class="row">
                      <div class="col-7 text-left"><span class="text-bold-600">Payable Amount: <span
                          class=""> ${{ parseFloat(paidInfo.paidAmount / 100).toFixed(2) }} </span></span></div>
                    </div>
                    <div class="row">
                      <div class="col-7 text-left"><span class="text-bold-600">Remaining Amount: <span
                          class=""> ${{ parseFloat(paidInfo.restAmount / 100).toFixed(2) }} </span></span></div>
                    </div>
                    <hr>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <p class="mt-1 text-bold-500">Payment Type</p>
                    </div>
                    <div class="col-12 ">
                      <!-- twenty five percent -->
                      <div class="custom-control-inline custom-control custom-radio">
                        <input v-model="payment_type" type="radio" class="custom-control-input"
                               id="paymentAmount25" name="paymentAmount" value="25">
                        <label class="custom-control-label font-weight-bold" for="paymentAmount25">Quarter
                        </label>
                      </div>
                      <!-- fifty percent -->
                      <div class="custom-control-inline custom-control custom-radio">
                        <input v-model="payment_type" type="radio" class="custom-control-input"
                               id="paymentAmount50" name="paymentAmount" value="50">
                        <label class="custom-control-label font-weight-bold" for="paymentAmount50">Half</label>
                      </div>
                      <!-- full  -->
                      <div class="custom-control-inline custom-control custom-radio">
                        <input v-model="payment_type" type="radio" class="custom-control-input"
                               id="paymentAmount100" name="paymentAmount" value="100">
                        <label class="custom-control-label font-weight-bold" for="paymentAmount100">Full
                        </label>
                      </div>

                      <!--  other -->
                      <div class="custom-control-inline custom-control custom-radio">
                        <input v-model="payment_type" type="radio" class="custom-control-input"
                               id="paymentAmountOther" name="paymentAmount" value="other">
                        <label class="custom-control-label font-weight-bold" for="paymentAmountOther">Other
                        </label>
                      </div>

                      <div class="row py-1" v-if="payment_type === 'other'">
                        <div class="col-6 align-self-center">
                          <input v-on:blur="blurEvent" v-model.number="paid_amount"
                                 placeholder="Enter a amount"
                                 type="number"
                                 class="form-control">
                        </div>
                        <div v-if="errorBag.paymentAmount.length > 0" class="text-left">
                          <p class="text-warning ml-1 mt-1 mb-0 font-medium-1"
                             v-for="(error, index) in errorBag.paymentAmount" :key="index">
                            {{ error }}</p>
                        </div>
                      </div>
                      <hr>
                    </div>
                  </div>
                  <div>
                    <h5 class="mt-0 text-bold-500">Payment Method</h5>

                    <div class="custom-control custom-radio d-flex align-items-center" :style="{marginBottom:'3px'}">
                      <input type="radio" class="custom-control-input" v-model="paymentMethod" name="customRadio"
                             value="cardPayment" id="CardPayment">
                      <label class="custom-control-label text-bold-400" for="CardPayment">Card Payment </label>
                    </div>

                    <div v-if="paymentMethod === 'cardPayment'" class="row mt-1">

                      <div class="col-12 ">
                        <div class="form-group">
                          <label for="first-name-icon">Cardholder name</label>
                          <div class="position-relative has-icon-left">
                            <input type="text" v-model="cardName" class="form-control text-capitalize" name="fname-icon"
                                   placeholder="Enter cardholder name">
                            <div class="form-control-position">
                              <i class='bx bx-user'></i>
                            </div>
                          </div>
                          <!-- backend error msg -->
                          <div
                              v-if="cardTokenBackendErrors.card_name !== undefined && cardTokenBackendErrors.card_name.length > 0 "
                              class="text-danger">
                            <p class="mb-0" v-for="(error,index) in cardTokenBackendErrors.card_name" :key="index">
                              {{ error }}</p>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 ">
                        <div class="form-group">
                          <label for="email-id-icon">Card number</label>
                          <div class="position-relative has-icon-left">
                            <input type="email" v-model.number="cardNumber" class="form-control" name="email-id-icon"
                                   placeholder="Enter card number">
                            <div class="form-control-position">
                              <i class='bx bx-credit-card-alt'></i>
                            </div>
                          </div>
                          <!-- backend error msg -->
                          <div
                              v-if="cardTokenBackendErrors.card_number !== undefined && cardTokenBackendErrors.card_number.length > 0 "
                              class="text-danger">
                            <p class="mb-0" v-for="(error,index) in cardTokenBackendErrors.card_number" :key="index">
                              {{ error }}</p>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-md-6">
                        <div class="form-group">
                          <label for="contact-info-icon">Expiry Date</label>
                          <div class="position-relative has-icon-left">
                            <input type="text" v-model="cardExpiryDate" id="expiryDateInput" class="form-control"
                                   name="contact-icon" placeholder="MM/YY" maxlength="5"
                                   :keyup="this.cardExpiryDate=this.cardExpiryDate.replace(/^(\d\d)(\d)$/g,'$1/$2').replace(/^(\d\d\/\d\d)(\d+)$/g,'$1/$2')">
                            <div class="form-control-position">
                              <i class='bx bx-calendar-check'></i>
                            </div>
                          </div>
                          <!-- backend error msg -->
                          <div
                              v-if="cardTokenBackendErrors.card_expire_month !== undefined && cardTokenBackendErrors.card_expire_month.length > 0 "
                              class="text-danger">
                            <p class="mb-0" v-for="(error,index) in cardTokenBackendErrors.card_expire_month"
                               :key="index"> {{ error }}</p>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-md-6">
                        <div class="form-group">
                          <label for="password-icon">CVV</label>
                          <div class="position-relative has-icon-left">
                            <input type="text" ref="cvvInput" v-model="cardCvc" class="form-control" name="contact-icon"
                                   placeholder="Enter CVV">
                            <div class="form-control-position">
                              <i class='bx bx-lock-alt'></i>
                            </div>
                          </div>
                          <!-- backend error msg -->
                          <div
                              v-if="cardTokenBackendErrors.card_cvc !== undefined && cardTokenBackendErrors.card_cvc.length > 0 "
                              class="text-danger">
                            <p class="mb-0" v-for="(error,index) in cardTokenBackendErrors.card_cvc" :key="index">
                              {{ error }}</p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="d-flex">
                      <div v-if="can('cash-payment-create')"
                           class="custom-control custom-radio d-flex align-items-center">
                        <input v-model="paymentMethod" value="cashPayment" type="radio" class="custom-control-input"
                               name="customRadio" id="cashPayment">
                        <label class="custom-control-label text-bold-400" for="cashPayment">Cash payment</label>
                      </div>
                      <div v-if="paymentMethod === 'cashPayment'"
                           class="custom-control custom-checkbox d-flex align-items-baseline ml-1">
                        <input class="custom-control-input" style="cursor: pointer;" type="checkbox" id="cashReceived"
                               v-model="isCashReceived">
                        <label class="custom-control-label text-warning" style="cursor: pointer;" for="cashReceived">Cash
                          Received </label>
                      </div>
                    </div>

                    <div class="custom-control custom-radio d-flex align-items-center">
                      <input v-model="paymentMethod" value="eftPayment" type="radio" class="custom-control-input"
                             name="customRadio" id="eftPayment">
                      <label class="custom-control-label text-bold-400" for="eftPayment">EFT payment</label>
                    </div>

                    <div v-if="paymentMethod === 'eftPayment'">
                      <div class="row py-1">
                        <div class="col-12 col-md-6 align-self-center">
                          <div class="form-group">
                            <input placeholder="Enter reference" v-model="eftReference" type="text"
                                   class="form-control">
                          </div>

                        </div>
                      </div>
                      <!-- showing error -->
                      <div v-if="frontEndErrors.eftReference.length > 0" class="text-left">
                        <p class="text-danger mb-0 font-medium-1" v-for="(error, index) in frontEndErrors.eftReference"
                           :key="index">{{ error }}</p>
                      </div>
                    </div>
                  </div>
                  <!-- showing error -->
                  <div v-if="frontEndErrors.paymentTypeSelection.length > 0" class="text-left pt-2">
                    <p class="text-danger mb-0 font-medium-1"
                       v-for="(error, index) in frontEndErrors.paymentTypeSelection"
                       :key="index">{{ error }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer border-0 pt-0">
          <button @click="payBtnHandler" type="button" class="btn btn-primary" :disabled="paymentMethod === ''">
            <i class="bx bx-x d-block d-sm-none"></i>
            <span class="d-none d-sm-block">Create Payment</span>
          </button>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import {mapActions, mapGetters} from "vuex";
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Loader from "@/components/backEnd/mixins/Loader";
import Authorization from "@/components/backEnd/mixins/Authorization";

export default {
  name: "RemainingPaymentModal",
  mixins: [ShowToastMessage, Loader, Authorization],
  props: ['singleOrder', 'remainingAmount'],
  emits: ['getPaidAmount'],
  data() {
    return {
      paymentMethod: '',
      isCashReceived: false,
      customPaymentAmount: '',
      paymentAmountType: 'full',
      totalPayable: 0,
      amount: 0,
      frontEndErrors: {
        paymentTypeSelection: [],
        eftReference: [],
        paymentAmountType: [],
        customPaymentAmount: []
      },

      customerAddress: '',
      shippingAddressStatus: false,
      shippingAddress: {
        name: '',
        email: '',
        phone: '',
        address: ''
      },
      term_condition: false,
      eft_reference: '',
      order_note: '',
      delivery_date: '',
      applyCoupon: false,
      applyCredit: false,
      cartsData: [],
      discount_type: '',
      coupon_code: '',
      credit_code: '',
      discount_amount: '',
      payment_type: '100',
      paid_amount: '',
      payment_method_type: '',
      discountObj: {},
      userId: '',
      errorBag: {
        eftReference: [],
        paymentAmount: [],
      },

      preOrderResponse: {
        message: '',
        status: '',
        remaining_credited_payment: '',
        order_delivery_charge: {
          applied_status: '',
          message: '',
          distance_value: '',
          distance_value_in_km: '',
          amount: '',
        },
        subtotal_amount: '',
        applied_discount: {
          applied_status: '',
          amount: ''
        },
        coupon_discount: {
          applied_status: '',
          validation_status: '',
          message: '',
          apply_on: '',
          amount: '',
        },
        credited_payment_discount: {
          applied_status: '',
          validation_status: '',
          amount: '',
          message: ''
        },
        total_discount_amount: '',
        gst_charge: {
          applied_status: '',
          amount: ''
        },
        grand_total: '',
        shipping_address_status: false,
        shipping_address: {},
      },
      customer: {
        address: {}
      },
      paidAmount: {},
      cardTokenBackendErrors: {},
      cardName: '',
      cardNumber: '',
      cardExpiryDate: '',
      cardExpireMonth: '',
      cardExpireYear: '',
      cardCvc: '',

      cardTokenResponse: {
        id: '',
        used: '',
        card: {},
        payment_gateway: '',
      },
      authorizationTokenResponse: {
        id: '',
        decline_reason: '',
        payment_status: ''
      },
      paymentID: '',
      eftReference: '',
      getSettingsParams: {
        type: ['payment'],
        key: ['payment_card_surcharge'],
      },
      paidInfo: {},
      order: {
        id: '',
        order_no: '',
        created_at: '',
        status: '',
      },
      customerID: null,
      cardCharges: {},
      postCardSurcharge: 0,
      paymentCreatedAt: '',
      cardPaymentAmountObj: {},
    }
  },
  computed: {
    ...mapGetters({
      authUser: 'appAuthLogin/authUser',
      payment: 'appPayments/payment',
      cardSurcharge: 'appSettings/settingPaymentCardSurcharge',
    }),

    customerFullName() {
      return `${this.singleOrder?.user?.first_name ?? ''} ${this.singleOrder?.user?.last_name ?? ''}`;
    },

    surcharge() {
      return this.cardSurcharge.value
    },
  },

  watch: {

    paid_amount(value) {
      if (value >= 0) {
        this.getPaidAmount()
      }
    },

    payment_type(paymentType) {
      this.getPaidAmount(paymentType)
    },

    paymentAmountType(current) {
      this.totalPayable = current === 'full' ? this.amount : '';
      this.customPaymentAmount = '';
    },

    remainingAmount(value) {
      this.amount = parseInt(value);
      this.totalPayable = parseInt(value);
      this.getPaidAmount(this.payment_type)
    },

    singleOrder(value) {
      this.order.id = value.id ?? '';
      this.order.order_no = value.order_no ?? '';
      this.customerID = value.user?.id;
      this.order.created_at = value.order_date;
      this.order.status = value.status;
    },

    customPaymentAmount(currentAmount) {
      if (this.paymentAmountType === 'custom') {
        if (parseFloat(currentAmount * 100).toFixed(2) > this.amount) {
          this.frontEndErrors.customPaymentAmount = [];
          this.frontEndErrors.customPaymentAmount.push(`Amount can't greater than $ ${parseFloat(this.amount / 100).toFixed(2)}`);
          this.totalPayable = parseInt(currentAmount * 100);
        } else {
          this.totalPayable = parseInt(currentAmount * 100);
          this.frontEndErrors.customPaymentAmount = [];
        }
      }
    },

    'paidInfo.paidAmount'() {
      this.cardCharges = this.surcharge.details.map((item) => {
        return {
          name: item.name.replaceAll('_', ' '),
          charge: parseInt(parseFloat(this.paidInfo.paidAmount) * parseFloat(item.rate) / 100),
        }
      })
    },

    cardExpiryDate(currentExpiryDate) {
      this.cardExpireMonth = currentExpiryDate.slice(0, 2);
      this.cardExpireYear = currentExpiryDate.slice(-2);
      if (currentExpiryDate.length === 5) {
        this.$refs.cvvInput.focus();
      }
    },
  },

  methods: {
    ...mapActions({
      getSettings: 'appSettings/getSettings',
      preStoreOrderData: 'appOrders/preStoreOrderData',
      cardTokenCreate: 'appPaymentGateways/cardTokenCreate',
      paymentCreate: 'appPaymentGateways/paymentCreate',
      paymentCreateByToken: 'appPaymentGateways/paymentCreateByToken',
      authorizationCreateByToken: 'appPaymentGateways/authorizationCreateByToken',
      postPayment: 'appPayments/postPayment',
      postOrder: 'appOrders/postOrder',
      postOrderNote: 'appOrderNotes/postOrderNote',
      postCardPayment: 'appCardPayments/postCardPayment',
      postCashPayment: 'appCashPayments/postCashPayment',
      postEftPayment: 'appEftPayments/postEftPayment',
      postOrderPayment: 'appOrderPayments/postOrderPayment',

      postPaymentCreationNotifyEmail: 'appPayments/postPaymentCreationNotifyEmail',
    }),
    // calculate surcharge
    getSurcharge(surchargeObj, cardType, amount) {
      if (surchargeObj.status === 1) {
        let cardRate = surchargeObj.details.find((cardItem) => {
              return cardItem.name.toLowerCase().trim() === cardType.toLowerCase().trim()
            })?.rate ?? 0,
            surchargeAmount = parseInt((amount * cardRate) / 100),
            totalAmount = amount + surchargeAmount;
        return {surcharge_amount: surchargeAmount, total_amount: totalAmount}
      } else {
        return {surcharge_amount: 0, total_amount: amount}
      }
    },

    blurEvent() {
      this.errorBag.paymentAmount = [];
    },

    async getPaidAmount(paymentType = 'other') {
      let paidAmount, restAmount, totalAmount;
      totalAmount = this.amount;
      if (paymentType === 'other') {
        paidAmount = Number(this.paid_amount) * 100 ?? 0;
        if (paidAmount > totalAmount) {
          paidAmount = totalAmount;
          this.paid_amount = parseFloat(totalAmount / 100).toFixed(2);
          this.errorBag.paymentAmount.push('Exceed total payable amount');
        }
      } else {
        paidAmount = parseInt(totalAmount * (Number(paymentType) / 100));
      }
      restAmount = totalAmount - paidAmount;
      this.paidInfo = {
        paymentType: paymentType,
        paidAmount: paidAmount,
        totalAmount: totalAmount,
        restAmount: restAmount
      }

    },
    async createOrderHandler() {
      // if add payment & cash payment & not check cash received
      if (this.payment_method_type === 'cashPayment' && !this.isCashReceived) {
        this.showToastMessage({
          type: 'warning',
          message: 'make sure you cash received otherwise select another payment type'
        });
        return;
      }
      if (this.paymentMethod === 'cardPayment') {
        const isTokenCreated = await this.tokenCreate();
        if (isTokenCreated) {
          const isAuthorized = this.order.status === 'Pending' ? await this.createAuthorizationByToken() : await this.createPaymentByToken();
          if (isAuthorized) {
            const isPaymentCreated = await this.createPayment();
            if (isPaymentCreated) {
              const isCardPaymentDone = await this.createCardPayment();
              if (isCardPaymentDone) {
                const isOrderPaymentCreated = await this.createOrderPayment();
                if (isOrderPaymentCreated) {
                  this.loader(false);
                  this.orderPaymentEmailNotify();
                  this.$emit('getPaidAmount', this.cardPaymentAmountObj);
                  this.resetData();
                  document.querySelector('[data-target="#remainingPaymentModal"]').click();
                  this.showToastMessage({
                    message: 'Payment created Successfully',
                    type: 'success'
                  });
                }
              }
            }
          }
        }
      } else if (this.paymentMethod === 'cashPayment') {

        const isPaymentCreated = await this.createPayment();
        if (isPaymentCreated) {
          const isCashPaymentDone = await this.createCashPayment();
          if (isCashPaymentDone) {
            const isOrderPaymentCreated = await this.createOrderPayment();
            if (isOrderPaymentCreated) {
              this.loader(false);
              this.orderPaymentEmailNotify();
              this.$emit('getPaidAmount', this.cardPaymentAmountObj);
              this.resetData();
              document.querySelector('[data-target="#remainingPaymentModal"]').click();
              this.showToastMessage({
                message: 'Payment created Successfully',
                type: 'success'
              });
            }
          }
        }
      } else {
        const isPaymentCreated = await this.createPayment();
        if (isPaymentCreated) {
          const isEftPaymentDone = await this.createEftPayment();
          if (isEftPaymentDone) {
            const isOrderPaymentCreated = await this.createOrderPayment();
            if (isOrderPaymentCreated) {
              this.loader(false);
              this.orderPaymentEmailNotify();
              this.$emit('getPaidAmount', this.cardPaymentAmountObj);
              this.resetData();
              document.querySelector('[data-target="#remainingPaymentModal"]').click();
              this.showToastMessage({
                message: 'Payment created Successfully',
                type: 'success'
              });
            }
          }
        }
      }
    },

    async tokenCreate() {

      const data = {
        card_name: this.cardName,
        card_number: this.cardNumber,
        card_expire_month: this.cardExpireMonth,
        card_expire_year: this.cardExpireYear,
        card_cvc: this.cardCvc,
      }
      this.loader(true);
      const response = await this.cardTokenCreate(data);
      this.loader(false);

      if (response.status === 200 || response.status === 201) {
        this.cardTokenBackendErrors = {};
        this.cardTokenResponse.id = response.data.id;
        this.cardTokenResponse.used = response.data.used;
        this.cardTokenResponse.card = response.data.card;
        this.cardTokenResponse.payment_gateway = response.data.payment_gateway;
        if (response.data.used === false) {
          let cardType = this.cardTokenResponse.card.type;
          this.cardPaymentAmountObj = this.getSurcharge(this.surcharge, cardType, this.paidInfo.paidAmount)
          return true;
        }

      } else {
        this.cardTokenResponse.id = '';
        this.cardTokenResponse.used = '';
        this.cardTokenResponse.card = {};
        this.cardTokenResponse.payment_gateway = '';
      }

      this.cardTokenBackendErrors = response.errors;
      if (response.message) {
        this.showToastMessage(response);
      }
      return false;

    },

    async createAuthorizationByToken() {
      let data = {
        currency: 'AUD',
        reference: 'order-admin-panel-future',
        amount: this.cardPaymentAmountObj.total_amount,
        description: `${this.customerFullName} paid $ ${this.cardPaymentAmountObj.total_amount} from from back-office Admin Panel(Order Future)`,
        card_token: this.cardTokenResponse.id,
      }

      this.loader(true);
      const response = await this.authorizationCreateByToken(data);
      this.loader(false);
      if (response.status === 200 || response.status === 201) {

        if (response.data.payment_status === 'APPROVED') {
          console.log(response, "authorized by token approved");
          this.authorizationTokenResponse.id = response.data.id;
          this.authorizationTokenResponse.payment_status = response.data.payment_status;
          this.authorizationTokenResponse.decline_reason = response.data.decline_reason;
          return true;
        }
        if (response.data.payment_status === 'DECLINED') {
          console.log(response, "authorized by token declined");
          this.authorizationTokenResponse.id = response.data.id;
          this.authorizationTokenResponse.payment_status = response.data.payment_status;
          this.authorizationTokenResponse.decline_reason = response.data.decline_reason;
          this.showToastMessage({type: 'error', message: 'Card  Declined'})
          return false;
        }
      }
      if (response.message) {
        this.showToastMessage(response);
      }
      return false;
    },

    async createPayment() {
      const data = {
        reference: this.order.order_no,
        type: this.paymentMethod === 'cardPayment' ? 1 : (this.paymentMethod === 'cashPayment' ? 0 : 2),// 0= cash, 1=card, 2= eftpos
        panel: 1,
      }
      this.loader(true);
      const response = await this.postPayment(data);
      this.loader(false);
      if (response.status === 200 || response.status === 201) {
        this.paymentID = this.payment.id
        this.paymentCreatedAt = this.payment.created_at
        return true;
      }
      if (response.message) {
        this.showToastMessage(response);
      }
      return false
    },

    async createCardPayment() {
      const data = {
        payment_id: this.paymentID,
        paid_by: this.customerID,
        amount: this.paidInfo.paidAmount,
        card_type: this.cardTokenResponse.card.type,
        card_surcharge: this.cardPaymentAmountObj.surcharge_amount,
        payment_gateway: this.cardTokenResponse.payment_gateway,
        payment_gateway_id: this.authorizationTokenResponse.id,
        status: this.order.status === 'Pending' ? 0 : 1 // 0 for hold, 1 for paid

      }
      this.loader(true);
      const response = await this.postCardPayment(data);
      this.loader(false);

      if (response.message) {
        this.showToastMessage(response);
      }
      return response.status === 200 || response.status === 201;

    },

    // cash payment api call
    async createCashPayment() {
      const data = {
        payment_id: this.paymentID,
        paid_by: this.customerID,
        paid_to: this.authUser.id,
        handover_date: '',
        amount: this.totalPayable,

      }
      this.loader(true);
      const response = await this.postCashPayment(data);
      this.loader(false);

      if (response.message) {
        this.showToastMessage(response);
      }
      return response.status === 200 || response.status === 201;

    },

    // eft payment api call
    async createEftPayment() {
      const data = {
        payment_id: this.paymentID,
        paid_by: this.customerID,
        payment_reference: this.eftReference,
        amount: this.totalPayable,

      }
      this.loader(true);
      const response = await this.postEftPayment(data);
      this.loader(false);
      if (response.message) {
        this.showToastMessage(response);
      }
      return response.status === 200 || response.status === 201;

    },

    async createOrderPayment() {
      // if order status is pending then no data insert on order payment table
      if (this.order.status === 'Pending') {
        return true;
      }
      const data = {
        payment_id: this.paymentID,
        order_id: this.order.id,
        transaction_date_time: this.paymentCreatedAt ?? this.order.created_at,
      };
      this.loader(true);
      const response = await this.postOrderPayment(data);
      this.loader(false);
      if (response.message) {
        this.showToastMessage(response);
      }
      return response.status === 200 || response.status === 201;

    },

    async createPaymentByToken() {
      let data = {
        currency: 'AUD',
        reference: 'order-admin-panel-future',
        amount: this.cardPaymentAmountObj.total_amount,
        description: `${this.customerFullName} paid $ ${this.cardPaymentAmountObj.total_amount} from from back-office Admin Panel(Order Future)`,
        card_token: this.cardTokenResponse.id,
      }
      this.loader(true);
      const response = await this.paymentCreateByToken(data);
      this.loader(false);
      if (response.status === 200 || response.status === 201) {
        if (response.data.payment_status === 'APPROVED') {
          console.log(response, "authorized by token success");
          this.authorizationTokenResponse.id = response.data.id;
          this.authorizationTokenResponse.payment_status = response.data.payment_status;
          this.authorizationTokenResponse.decline_reason = response.data.decline_reason;
          return true;
        }
        if (response.data.payment_status === 'DECLINED') {
          console.log(response, "authorized by token declined");
          this.authorizationTokenResponse.id = response.data.id;
          this.authorizationTokenResponse.payment_status = response.data.payment_status;
          this.authorizationTokenResponse.decline_reason = response.data.decline_reason;
          this.showToastMessage({type: 'error', message: 'Card  Declined'})
          return false;
        }

      }
      if (response.message) {
        this.showToastMessage(response);
      }
      return false;
    },

    orderPaymentEmailNotify() {
      const data = {
        id: this.paymentID,
        params: {
          notify_customer: 1,
          notify_internal_user: 1
        }

      };
      this.postPaymentCreationNotifyEmail(data);
    },

    async payBtnHandler() {
      console.log('clicked');
      if (this.paymentAmountType === 'custom') {
        if (this.customPaymentAmount >= 1) {
          console.log('inner');
          if (parseFloat(this.customPaymentAmount * 100).toFixed(2) > this.amount) {
            this.frontEndErrors.customPaymentAmount = [];
            this.frontEndErrors.customPaymentAmount.push(`Amount can't greater than $ ${parseFloat(this.amount / 100).toFixed(2)}`);
          } else {
            console.log('inner else');
            this.frontEndErrors.customPaymentAmount = [];
            await this.createOrderHandler();
          }
        } else {
          this.frontEndErrors.customPaymentAmount = [];
          this.frontEndErrors.customPaymentAmount.push(`Please enter amount and amount can't greater than $ ${parseFloat(this.amount / 100).toFixed(2)}`);
        }

      } else {
        console.log('api call');
        await this.createOrderHandler();
      }

    },

    resetData() {
      Object.assign(this.$data, this.$options.data.apply(this));
    }
  }
}
</script>

<style scoped>
.custom-control-label {
  font-size: 1rem;
  cursor: pointer;
}

.payment-input-field {
  border: 1px solid #c5c5c5;
  border-radius: 5px;
  width: 100%;
  padding: 4px 15px
}

.payment-input-field:focus {
  outline: 0 !important;
}

.payment-input-field:focus {
  border: 1px solid #5a8dee !important;
}

</style>